<template>
  <van-popup v-model="shareCheckboxMessage" class="share-popup">
    <div class="full-screen-popup">
      <div class="popup-header">
        <i
          class="van-icon van-icon-cross close-icon"
          @click="closeSharePopup"
        ></i>
      </div>
      <div class="selected-messages-container">
        <div v-for="(item, index) in selectedMessages" :key="index">
          <p class="message-text" v-if="item.text">{{ item.text }}</p>
          <img
            class="message-image"
            v-if="item.imageUrl"
            :src="item.imageUrl"
            alt="Selected Image"
          />
          <p v-if="item.deeplink" class="message-text">
            <a  class="deeplink" :href="item.deeplink">{{
                item.deeplink
              }}</a>
          </p>
        </div>
      </div>
      <div class="share-button-container">
        <van-button
          class="share-button"
          type="info"
          @click="shareContent"
          block
        >
          <i class="van-icon van-icon-share dark-icon"></i>
        </van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { Button, Popup } from "vant";
import { eventBus } from "@/plugins/eventBus";
// import defaultImage from "@/assets/images/wiki_logo.png";

export default {
  props: {
    shareCheckboxMessage: Boolean,
    selectedMessages: Array,
  },
  components: {
    "van-popup": Popup,
    "van-button": Button,
  },
  methods: {
    closeSharePopup() {
      this.$emit("update:shareCheckboxMessage", false);
    },
    shareContent() {
      const dataId = this.selectedMessages
        .filter((item) => item.id)
        .map((item) => item.id);

      this.selectDeepLinkData(dataId);
      this.closeSharePopup();
    },
    selectDeepLinkData(item) {
      eventBus.$emit("deepLinkData", item);
    },
  },
};
</script>
<style scoped>
.message-image {
  width: 256px;
}

.message-text {
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
  font-size: 14px;
  line-height: 18px;
  color: #000000 !important;
  margin-left: 1%;
  margin-right: 1%;
}

.share-popup {
  height: 100%;
  width: 100%;
}

.full-screen-popup {
  background-color: white;
}

.popup-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.close-icon {
  font-size: 24px;
  cursor: pointer;
}

.popup-content {
  /* Стили для содержимого всплывающего окна */
}

.share-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.share-button {
  width: 100%;
  max-width: none;
  background-color: #e0e5e5;
  border: none;
}

.dark-icon {
  color: #000;
}

.selected-messages-container {
  position: fixed;
  max-height: 87%;
  overflow-y: auto;
  width: 100%;
  text-align: center;
}

a.deeplink,
a.deeplink:visited,
a.deeplink:active {
  text-decoration: underline;
  color: #0b0080;
}
</style>
