import api from "@/plugins/api/EntryPoint";

export default {
  dataForPopup(uniqueNumber, callBackFn) {
    return api.post(
      "deeplink-data",
      { uniqueNumber: uniqueNumber },
      callBackFn
    );
  },
  saveDeepLinkDataToDb(deeplinkData, callBackFn) {
    console.log("saveDeepLinkDataToDb: " + JSON.stringify(deeplinkData));
    return api.post(
      "save-deeplink-data",
      { deeplinkData: deeplinkData },
      callBackFn
    );
  },
};
