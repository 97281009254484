import Popup from "@/plugins/api/factories/DeeplinkPopup";

export const state = {
  uniqueNumber: null,
  deepLinkData: [],
  deepLinkPopup: false,
};

export const actions = {
  async sendUniqueNumber({ commit, dispatch }, uniqueNumber) {
    commit("SET_UNIQUE_NUMBER", uniqueNumber);
    dispatch("deeplinkDataForPopup");
  },
  deeplinkDataForPopup({ state, commit }) {
    return new Promise((resolve, reject) => {
      Popup.dataForPopup(
        state.uniqueNumber,
        (response) => {
          resolve(response);
          commit("SET_DEEP_LINK_DATA", response.data);
          commit("DEEP_LINK_POPUP", true);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  saveDeepLinkData({ state }) {
    return new Promise((resolve, reject) => {
      Popup.saveDeepLinkDataToDb(
          state.deepLinkData,
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
      );
    });
  },
};

export const mutations = {
  SET_UNIQUE_NUMBER(state, uniqueNumber) {
    state.uniqueNumber = uniqueNumber;
  },
  SET_DEEP_LINK_DATA(state, deepLinkData) {
    state.deepLinkData = deepLinkData;
    console.log("SET_DEEP_LINK_DATA: " + JSON.stringify(state.deepLinkData));
  },
  DEEP_LINK_POPUP(state, deepLinkPopup) {
    state.deepLinkPopup = deepLinkPopup;
    console.log("DEEP_LINK_POPUP: " + JSON.stringify(state.deepLinkPopup));
  },
};
