import api from "@/plugins/api/EntryPoint";

export default {
  addDeepLinkData(deeplinkData, callBackFn) {
    return api.post(
      "deeplink",
      { deeplinkData: deeplinkData },
      callBackFn
    );
  },
  uploadPhoto(photoData, callBackFn) {
    return api.post("photo-upload", { photo: photoData }, callBackFn);
  },
  firstMessage(message, callBackFn) {
    return api.post("dialogue/first-message", { prompt: message }, callBackFn);
  },
  sendMessageApiGpt(message, pageNumber, callBackFn) {
    return api.post(
      "chat",
      { prompt: message, pageNumber: pageNumber },
      callBackFn
    );
  },
  generateImageApiGpt(message, callBackFn) {
    return api.post("get-image", { prompt: message }, callBackFn);
  },
  getDialogueApiGpt(callBackFn) {
    return api.get("dialogue/get", callBackFn);
  },
  getLastMessageDialogueApiGpt(modal, callBackFn) {
    return api.post("dialogue/get-last-message", { modal: modal }, callBackFn);
  },
  getDialogueApiGptPerPage(pageNumber, callBackFn) {
    return api.post("dialogue/get-per-page", { page: pageNumber }, callBackFn);
  },
  deleteMessage(messageId, callBackFn) {
    return api.post(
      "dialogue/delete-message",
      { message_id: messageId },
      callBackFn
    );
  },
  editDialogueMessage(data, callBackFn) {
    console.log("editDialogueMessage" + JSON.stringify(data))
    return api.post("dialogue/edit-message", data, callBackFn);
  },
  storeAiChat(aiChat, callBackFn) {
    console.log("storeAiChat fabric" + JSON.stringify(aiChat))
    return api.post("store-ai-chat", { aiChatModel: aiChat.model, aiChatType: aiChat.type, aiImageSize: aiChat.imageSize }, callBackFn);
  },
};

