<template>
  <div class="input-wrapper">
    <van-popup
      v-model="localTranslateLanguagePicker"
      @input="closeTranslateLanguagePicker"
      position="bottom"
    >
      <div class="language-header">
        <span class="language-picker-title" @click="canselTranslate()">
          {{ $t("TranslateLanguagePicker.canselTranslate") }}
        </span>
        <span class="language-picker-title">
          {{ $t("TranslateLanguagePicker.translateTo") }}
        </span>
      </div>
      <div class="custom-picker">
        <div class="language-list">
          <div
            class="language-item"
            v-for="locale in locales"
            :key="locale.value"
            @click="selectLanguage(locale)"
          >
            <img :src="getFlagIcon(locale.value)" class="picker-icon" />
            <p class="language-lang">{{ $t("components.picker." + locale.value) }}</p>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Popup from "vant/lib/popup/index";
import { eventBus } from "@/plugins/eventBus";

export default {
  name: "TranslateLanguagePicker",
  components: {
    "van-popup": Popup,
  },
  props: {
    TranslateLanguagePicker: Boolean,
    TranslateMessage: Object,
    value: String,
  },
  data() {
    return {
      locales: [],
      language: "",
    };
  },
  computed: {
    localTranslateLanguagePicker: {
      get() {
        return this.TranslateLanguagePicker;
      },
      set(value) {
        this.$emit("update:TranslateLanguagePicker", value);
      },
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    locale() {
      this.prepareLocales();
    },
  },
  created() {
    this.prepareLocales();
  },
  methods: {
    canselTranslate() {
      eventBus.$emit("canselTranslate");
      this.closeTranslateLanguagePicker();
    },
    closeTranslateLanguagePicker() {
      this.$emit("update:TranslateLanguagePicker", false);
    },
    getFlagIcon(locale) {
      return require(`@/assets/images/language/${locale}.png`);
    },
    selectLanguage(lang) {
      if (Object.keys(this.TranslateMessage).length !== 0) {
        let message = this.TranslateMessage.text;
       let data = {
          message: message,
          lang: lang
        }
        eventBus.$emit("TranslateMessage", data);
        this.closeTranslateLanguagePicker();
      } else {
        this.language = lang.value;
        eventBus.$emit("languageSelected", lang);
        this.closeTranslateLanguagePicker();
      }
    },
    prepareLocales() {
      this.locales = [];
      let locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
      for (let i = 0; i < locales.length; i++) {
        this.locales.push({
          text: this.$t("components.picker." + locales[i]),
          value: locales[i],
        });
      }
    },
  },
};
</script>

<style scoped>
@import "../../../node_modules/vant/lib/popup/index.css";
.custom-picker {
  margin-top: 20%;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  max-height: 30vh;
  overflow-y: auto;
}

.language-header {
  width: 100%;
  height: 25%;
  position: absolute;
  top: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.language-picker-title {
  margin: 10px;
}

.picker-icon {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}

.language-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.language-item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  position: relative;
}

.language-item::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #918dab;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 30px;
}

.language-item img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.language-item p {
  font-size: 14px;
  color: #918dab;
  margin: 0;
}
</style>
