import Account from "@/plugins/api/factories/Account";
import Auth from "@/plugins/api/factories/Auth";
import Settings from "@/plugins/api/factories/Settings";
import Ai from "@/plugins/api/factories/Ai";
import store from "@/store";

export const state = {
  hidden: false,
  guest: false,
  authLog: "",
  nativeData: null,
  authToken: null,
  userId: null,
  status: null,
  currentChat: {
    model: null,
    type: null,
    logo: null,
    imageSize: null,
  },
  currentCompanyLogo: null,
  appTheme: "dark",
  themeReady: false,
  accountNumber: null,
  phoneNumber: null,
  locale: process.env.VUE_APP_I18N_DEFAULT_LOCALE,
  requestedPage: null,
  answers: {},
  questions: {},
  platform: null,
  getGuestCounter: 0,
  userPoints: null,
};

export const mutations = {
  INCREMENT_GET_GUEST_COUNTER(state) {
    state.getGuestCounter++;
  },
  WRITE_AUTH_LOG(state, logString) {
    state.authLog = state.authLog + ";" + logString;
  },
  SET_APP_THEME(state, appTheme) {
    // document.body.classList.remove("app-theme-" + state.appTheme);
    state.appTheme = appTheme;
    state.themeReady = true;
    // document.body.classList.add("app-theme-" + appTheme);

    Settings.setTheme(appTheme);
  },
  CHANGE_USER_THEME(state, theme) {
    Settings.setTheme(theme);
  },
  SET_REQUESTED_PAGE(state, requestedPage) {
    state.requestedPage = requestedPage;
  },
  SET_ACCOUNT_NUMBER(state, accountNumber) {
    state.accountNumber = accountNumber;
  },
  SET_NATIVE_DATA(state, nativeData) {
    state.nativeData = nativeData;
  },
  SET_AUTH_TOKEN(state, data) {
    state.authToken = data.token;
  },
  SET_USER_ID(state, data) {
    state.userId = data.user_id;
  },
  SET_STATUS(state, status) {
    state.status = status.status;
  },
  SET_CURRENT_CHAT_MODEL(state, current_chat_model) {
    state.currentChat.model = current_chat_model;
  },
  SET_CURRENT_CHAT_TYPE(state, current_chat_type) {
    state.currentChat.type = current_chat_type;
  },
  SET_CURRENT_IMAGE_SIZE(state, current_image_size) {
    state.currentChat.imageSize = current_image_size;
    console.log("SET_CURRENT_IMAGE_SIZE" + JSON.stringify(state.currentChat.imageSize));
  },
  SET_CURRENT_CHAT_LOGO(state, current_chat_logo) {
    state.currentChat.logo = current_chat_logo;
  },
  SET_PHONE_NUMBER(state, phoneNumber) {
    state.phoneNumber = phoneNumber;
  },
  SET_LOCALE(state, locale) {
    let locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
    if (locales.indexOf(locale) >= 0) {
      state.locale = locale;
    } else {
      state.locale = process.env.VUE_APP_I18N_DEFAULT_LOCALE;
    }
  },
  SET_GUEST(state, guest) {
    state.guest = guest;
  },
  SET_HIDDEN(state, hidden) {
    state.hidden = hidden;
  },
  SET_QUESTIONS(state, questions) {
    state.questions = questions;
  },
  SET_ANSWERS(state, answers) {
    state.answers = answers;
  },
  SET_PLATFORM(state, platform) {
    state.platform = platform;
  },
  SET_USER_POINTS(state, userPoints) {
    state.userPoints = userPoints;
    console.log("state.userPoints " + state.userPoints);
  },
};

export const getters = {
  nativeData(state) {
    return state.nativeData;
  },
};

export const actions = {
  authenticate({ commit, dispatch }, authData) {
    commit("SET_NATIVE_DATA", authData);
    commit("SET_PLATFORM", authData.platform);
    dispatch("newNativeData");
  },
  authenticateListenApple({ commit, dispatch }, authListenData) {
    commit("SET_NATIVE_DATA", authListenData);
    commit("SET_PLATFORM", authListenData.platform);
    dispatch("newAppleAuth");
  },
  authenticateListenGoogle({ commit, dispatch }, authListenData) {
    commit("SET_NATIVE_DATA", authListenData);
    commit("SET_PLATFORM", authListenData.platform);
    dispatch("newGoogleAuth");
  },
  storeGuest({ state }, callBackFn) {
    Account.guestMode(state.guest, (res) => {
      callBackFn(res);
    });
  },
  getGuest({ commit }) {
    Account.getGuestMode((response) => {
      commit("SET_GUEST", response.guest);
    });
  },
  getUserPoints({ commit }) {
    Account.getUserPoints((response) => {
      console.log("getUserPoints" + JSON.stringify(response));
      commit("SET_USER_POINTS", response.points);
    });
  },
  async setupUserSettings({ commit, state }) {
    return new Promise((resolve) => {
      Settings.getLanguage(async (lang) => {
        let selectedLocale;
        if (lang.lang === null) {
          await Settings.putLanguage(state.nativeData.lang);
          selectedLocale = state.nativeData.lang;
        } else {
          selectedLocale = lang.lang;
        }
        commit("SET_LOCALE", selectedLocale);
        commit("HeaderScript/SET_LANG", selectedLocale, { root: true });

        await store.dispatch("HeaderScript/allScript");

        commit("Settings/UPDATE_LANGUAGE", selectedLocale, { root: true });
        console.log("!!!!!!!!!!!!LANG " + selectedLocale);

        resolve();
      });
    });
  },
  async setUserPhone({ state, commit }) {
    console.log("TRY TO SET PHONE");
    if (state.authToken !== null && state.authToken !== undefined) {
      await Auth.getPhoneNumber((response) => {
        console.log("SET PHONE: " + response.phone);
        commit("SET_PHONE_NUMBER", response.phone);
        window.Tawk_API.setAttributes({
          phone: response.phone,
          link: "https://support.billing.city/user/" + response.id,
        });
      });
    }
  },
  getAndSetupTheme({ commit, state }, theme) {
    return new Promise((resolve) => {
      Settings.getTheme(async (res) => {
        console.log("GET AND SETUP THEME");
        state.themeReady = false;
        const themeToSetup = res.theme ? res.theme : theme ? theme : "dark";
        commit("SET_APP_THEME", themeToSetup);
        this.nativeSendEvent("setThemeMode", themeToSetup);
        resolve();
      });
    });
  },
  newNativeData({ state, commit }) {
    commit("INCREMENT_GET_GUEST_COUNTER");
    return new Promise((resolve, reject) => {
      Account.nativeAuth(
        state.nativeData,
        (response) => {
          resolve(response);
          console.log("newNativeData: " + JSON.stringify(response.data));
          commit("SET_AUTH_TOKEN", response.data);
          commit("SET_USER_ID", response.data);
          commit("SET_STATUS", response.data);
          commit("SET_CURRENT_CHAT_MODEL", response.data.current_chat_model);
          commit("SET_CURRENT_CHAT_TYPE", response.data.current_chat_type);
          commit("SET_CURRENT_IMAGE_SIZE", response.data.current_image_size);
          commit("SET_USER_POINTS", response.data.user_points)
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  newGoogleAuth({ state, commit }) {
    return new Promise((resolve, reject) => {
      Account.googleAuth(
        state.nativeData,
        (response) => {
          resolve(response);
          commit("SET_AUTH_TOKEN", response.data);
          commit("SET_USER_ID", response.data);
          commit("SET_STATUS", response.data);
          commit("SET_CURRENT_CHAT_MODEL", response.data.current_chat_model);
          commit("SET_CURRENT_CHAT_TYPE", response.data.current_chat_type);
          commit("SET_CURRENT_IMAGE_SIZE", response.data.current_image_size);
          commit("SET_USER_POINTS", response.data.user_points)
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  newAppleAuth({ state, commit }) {
    return new Promise((resolve, reject) => {
      Account.appleAuth(
        state.nativeData,
        (response) => {
          resolve(response);
          commit("SET_AUTH_TOKEN", response.data);
          commit("SET_USER_ID", response.data);
          commit("SET_STATUS", response.data);
          commit("SET_CURRENT_CHAT_MODEL", response.data.current_chat_model);
          commit("SET_CURRENT_CHAT_TYPE", response.data.current_chat_type);
          commit("SET_CURRENT_IMAGE_SIZE", response.data.current_image_size);
          commit("SET_USER_POINTS", response.data.user_points)
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  deleteUser(callBackFn) {
    return Account.deleteUser((res) => {
      callBackFn(res);
    }).then(() => {
      this.$forceUpdate();
    });
  },
  getUserLanguage({ commit }) {
    return Settings.getLanguage((response) => {
      return commit("SET_LOCALE", response);
    });
  },
  getQuestions({ commit }) {
    return Account.getQuestions((response) => {
      return commit("SET_QUESTIONS", response);
    });
  },
  async saveAnswers({ commit }, answers) {
    commit("SET_ANSWERS", answers);
    return await Account.putAnswers(answers);
  },
  storeAiChat({ state }) {
    console.log("storeAiChat" + JSON.stringify(state.currentChat));
    return new Promise((resolve, reject) => {
      Ai.storeAiChat(
        state.currentChat,
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};
