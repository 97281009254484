<template>
  <header class="header">
    <van-row class="burger-menu-row">
      <van-col span="4" class="burger-menu">
        <template v-if="this.$route.name !== 'Home'">
          <BackButton />
        </template>
        <template v-else>
          <div class="menu-wrapper" @click="tap(), openBurgerMenu()">
            <van-icon
              style="padding-left: 25%"
              name="wap-nav"
              class="icon-size"
            />
            <span style="padding-left: 25%">{{ $t("header.menu") }}</span>
          </div>
          <van-popup style="z-index: 9999 !important;" class="van-popup-left" v-model="showMenu" position="left">
            <van-cell>
              <!--              <template v-if="status_sub === 1">-->
              <!--                <span class="burger-status-title">-->
              <!--                  {{ $t("header.burgerMenu.accountStatus") }}</span-->
              <!--                >-->
              <!--                <span class="account-status-true">{{-->
              <!--                  $t("header.burgerMenu.premium")-->
              <!--                }}</span>-->
              <!--              </template>-->
              <!--              <template v-else>-->
              <!--                <span class="burger-status-title">-->
              <!--                  {{ $t("header.burgerMenu.accountStatus") }}</span-->
              <!--                >-->
              <!--                <span class="account-status-false">{{-->
              <!--                  $t("header.burgerMenu.free")-->
              <!--                }}</span>-->
              <!--              </template>-->
              <span class="burger-status-title">
                {{ $t("header.burgerMenu.currentPoints") }}</span
              >
              <span class="account-status-false">{{ this.currentPoints }}</span>
            </van-cell>
            <van-cell>
              <van-radio-group v-model="radio" @change="handleRadioChange">
                <div class="radio-item">
                  <img
                      src="../../assets/images/open_ai_logo.png"
                      alt="My Icon"
                  />
                  <div class="radio-title">OPEN AI</div>
                </div>
                <div class="radio-subtitle">{{ $t("header.imageMaker") }}</div>
                <div class="radio-subtitle-2">Dall-e-3</div>
                <van-radio
                  class="radio-text"
                  name="dall-e-3,1024x1024"
                  @click="showMenu = false"
                  >1024x1024</van-radio
                >
                <van-radio
                    class="radio-text"
                    name="dall-e-3,1024x1792"
                    @click="showMenu = false"
                >1024x1792</van-radio
                >
                <van-radio
                    class="radio-text"
                    name="dall-e-3,1792x1024"
                    @click="showMenu = false"
                >1792x1024</van-radio
                >
                <van-radio
                    class="radio-text"
                    name="dall-e-3,1024x1024.hd"
                    @click="showMenu = false"
                >1024x1024 HD</van-radio
                >
                <van-radio
                    class="radio-text"
                    name="dall-e-3,1024x1792.hd"
                    @click="showMenu = false"
                >1024x1792 HD</van-radio
                >
                <van-radio
                    class="radio-text"
                    name="dall-e-3,1792x1024.hd"
                    @click="showMenu = false"
                >1792x1024 HD</van-radio
                >
                <div class="radio-subtitle-2">Dall-e-2</div>
                <van-radio
                    class="radio-text"
                    name="dall-e-2,256x256"
                    @click="showMenu = false"
                >256x256</van-radio
                >
                <van-radio
                    class="radio-text"
                    name="dall-e-2,512x512"
                    @click="showMenu = false"
                >512x512</van-radio
                >
                <van-radio
                  class="radio-text"
                  name="dall-e-2,1024x1024"
                  @click="showMenu = false"
                  >1024×1024</van-radio
                >
                <div class="radio-subtitle">{{ $t("header.chat") }}</div>
                <van-radio
                  class="radio-text"
                  name="gpt-4"
                  @click="showMenu = false"
                  >GPT-4</van-radio
                >
                <van-radio
                  class="radio-text"
                  name="gpt-3.5-turbo"
                  @click="showMenu = false"
                  >GPT-3.5 Turbo</van-radio
                >

                <!--                <div class="radio-item">-->
                <!--                  <img-->
                <!--                    src="../../assets/images/google_bard_logo.svg"-->
                <!--                    alt="My Icon"-->
                <!--                  />-->
                <!--                  <div class="radio-title">Google AI</div>-->
                <!--                </div>-->
                <!--                <div class="radio-subtitle">{{ $t("header.chat") }}</div>-->
                <!--                <van-radio-->
                <!--                  class="radio-text"-->
                <!--                  name="bard-14"-->
                <!--                  @click="showMenu = false"-->
                <!--                  >Chat Bard 14</van-radio-->
                <!--                >-->
              </van-radio-group>
            </van-cell>
            <van-cell class="burger-van-cell">
              <template #title>
                <div class="burger-title">
<!--                  {{ $t("header.burgerMenu.advertisement") }}-->

                </div>
              </template>
            </van-cell>
            <van-cell class="burger-van-cell" @click="watchAdvertisement(), (showMenu = false)">
              <template #icon>
                <img
                    class="burger-image"
                    src="./../../assets/images/advertisement-icon.png"
                    alt="My Icon"
                />
              </template>
              <template #title>
                <div class="burger-title">
                  {{ $t("header.burgerMenu.advertisement") }}
                </div>
              </template>
            </van-cell>
            <van-cell class="burger-van-cell" @click="settings(), (showMenu = false)">
              <template #icon>
                <img
                  class="burger-image"
                  src="./../../assets/images/settings.png"
                  alt="My Icon"
                />
              </template>
              <template #title>
                <div class="burger-title">
                  {{ $t("header.burgerMenu.settings") }}
                </div>
              </template>
            </van-cell>
            <van-cell class="burger-van-cell" @click="payments(), (showMenu = false)">
              <template #icon>
                <img
                  class="burger-image"
                  src="./../../assets/images/credit-card.png"
                  alt="My Icon"
                />
              </template>
              <template #title>
                <div class="burger-title">
                  {{ $t("header.burgerMenu.payment") }}
                </div>
              </template>
            </van-cell>
            <van-cell class="burger-van-cell" @click="authorization(), (showMenu = false)">
              <template #icon>
                <img
                  class="burger-image"
                  src="./../../assets/images/autentication.png"
                  alt="My Icon"
                />
              </template>
              <template #title>
                <div class="burger-title">
                  {{ $t("header.burgerMenu.authorization") }}
                </div>
              </template>
            </van-cell>
          </van-popup>
        </template>
      </van-col>
      <van-col
        v-if="currentChatModel === null"
        span="8"
        class="ai-info-container"
        @click="tap(), openBurgerMenu()"
      >
        <div class="title-ai">SELECT AI</div>
        <img
          class="image-arrow-right"
          src="../../assets/images/arrow-right.png"
          alt="My Icon"
        />
      </van-col>
      <van-col
        v-else
        span="8"
        class="ai-info-container"
        @click="tap(), openBurgerMenu()"
      >
        <img
          v-if="openAiModels.includes(currentChatModel)"
          class="image-ai-info"
          src="../../assets/images/open_ai_logo.png"
          alt="My Icon"
        />
        <img
          v-if="googleAiModels.includes(currentChatModel)"
          class="image-ai-info"
          src="../../assets/images/google_bard_logo.svg"
          alt="My Icon"
        />
        <div class="title-ai-info-container">
          <p v-if="chatModels.includes(currentChatModel)" class="title-ai-info">
            {{ $t("header.chat") }}
          </p>
          <p
            v-if="imageModels.includes(currentChatModel)"
            class="title-ai-info"
          >
            {{ $t("header.imageMaker") }}
          </p>
          <p v-if="currentChatType === 'text'" class="text-ai-info">{{ currentChatModel }}</p>
          <p v-if="currentChatType === 'image'" class="text-ai-info">{{ currentChatModel + ', ' + currentImageSize }}</p>
        </div>
        <img
          class="image-arrow-right"
          src="../../assets/images/arrow-right.png"
          alt="My Icon"
        />
      </van-col>
    </van-row>
    <van-row
      v-if="
        currentChatModel !== null &&
        this.$route.name === 'Home' &&
        chatModels.includes(currentChatModel)
      "
      class="top-menu-row"
    >
<!--      <van-col span="4" class="top-menu-item">-->
<!--        <div class="menu-wrapper location-button" @click="toggleMap(), tap()">-->
<!--          <van-icon name="location" class="icon icon-size" />-->
<!--          <span>{{ $t("header.location") }}</span>-->
<!--        </div>-->
<!--        <van-popup-->
<!--          class="van-popup-top location"-->
<!--          v-model="locationMenu"-->
<!--          position="top"-->
<!--        >-->
<!--          <van-icon-->
<!--            @click="locationMenu = false"-->
<!--            name="cross"-->
<!--            class="close-map-icon"-->
<!--          />-->
<!--          <Map :coordinates="mapCoordinates" />-->
<!--          <div-->
<!--            class="wrapper-dropdown"-->
<!--            @click="toggleDropdown"-->
<!--            :class="{ active: isDropdownOpen }"-->
<!--          >-->
<!--            <div class="selected-display">-->
<!--              <span>{{ $t("header.locationAround") }}</span>-->
<!--            </div>-->
<!--            <div class="arrow">-->
<!--              <svg-->
<!--                width="24"-->
<!--                height="24"-->
<!--                viewBox="0 0 24 24"-->
<!--                fill="none"-->
<!--                xmlns="http://www.w3.org/2000/svg"-->
<!--              >-->
<!--                <path-->
<!--                  d="M7 14.5l5-5 5 5"-->
<!--                  stroke="black"-->
<!--                  stroke-width="1.5"-->
<!--                  stroke-linecap="round"-->
<!--                  stroke-linejoin="round"-->
<!--                ></path>-->
<!--              </svg>-->
<!--            </div>-->
<!--            <ul class="dropdown">-->
<!--              <li-->
<!--                class="item"-->
<!--                @click="selectOption($t('header.mapMenu.attractions'))"-->
<!--              >-->
<!--                {{ $t("header.mapMenu.attractions") }}-->
<!--              </li>-->
<!--              <li-->
<!--                class="item"-->
<!--                @click="selectOption($t('header.mapMenu.restAndCafes'))"-->
<!--              >-->
<!--                {{ $t("header.mapMenu.restAndCafes") }}-->
<!--              </li>-->
<!--              <li-->
<!--                class="item"-->
<!--                @click="selectOption($t('header.mapMenu.hotelsAndInns'))"-->
<!--              >-->
<!--                {{ $t("header.mapMenu.hotelsAndInns") }}-->
<!--              </li>-->
<!--              <li-->
<!--                class="item"-->
<!--                @click="selectOption($t('header.mapMenu.shops'))"-->
<!--              >-->
<!--                {{ $t("header.mapMenu.shops") }}-->
<!--              </li>-->
<!--              <li-->
<!--                class="item"-->
<!--                @click="selectOption($t('header.mapMenu.pharmacies'))"-->
<!--              >-->
<!--                {{ $t("header.mapMenu.pharmacies") }}-->
<!--              </li>-->
<!--              <li class="item" @click="selectOption($t('header.mapMenu.atm'))">-->
<!--                {{ $t("header.mapMenu.atm") }}-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </van-popup>-->
<!--      </van-col>-->
<!--      <van-col span="4" class="top-menu-item">-->
<!--        <div-->
<!--          class="menu-wrapper photo-button"-->
<!--          @click="(photoMenu = true), tap()"-->
<!--        >-->
<!--          <van-icon name="photograph" class="icon icon-size" />-->
<!--          <span>{{ $t("header.upload") }}</span>-->
<!--        </div>-->
<!--        <van-popup class="van-popup-top" v-model="photoMenu" position="top">-->
<!--          <van-cell @click="takeGallery(), tap(), (photoMenu = false)">-->
<!--            <van-icon name="photo-o" class="menu-icon" />-->
<!--            <span>{{ $t("header.uploadMenu.media") }}</span>-->
<!--          </van-cell>-->
<!--          <van-cell @click="takePhoto(), tap(), (photoMenu = false)">-->
<!--            <van-icon name="photograph" class="menu-icon" />-->
<!--            <span>{{ $t("header.uploadMenu.photo") }}</span>-->
<!--          </van-cell>-->
<!--        </van-popup>-->
<!--      </van-col>-->
      <!--      <van-col span="3" class="top-menu-item">-->
      <!--        <div class="menu-wrapper lock-button" @click="(lockMenu = true), tap()">-->
      <!--          <van-icon name="lock" class="icon icon-size" badge="!" />-->
      <!--          <span>{{ $t("header.status") }}</span>-->
      <!--        </div>-->
      <!--        <van-popup class="van-popup-top" v-model="lockMenu" position="top">-->
      <!--          <van-cell @click="shareApp(), tap()">-->
      <!--            <van-icon name="share-o" class="menu-icon" />-->
      <!--            <span>{{ $t("header.statusMenu.share") }}</span>-->
      <!--          </van-cell>-->
      <!--        </van-popup>-->
      <!--      </van-col>-->
      <van-col span="4" class="top-menu-item">
        <div
          class="menu-wrapper ellipsis-button"
          @click="(ScriptMenu = true), tap()"
        >
          <van-icon name="more" class="icon icon-size" />
          <span>{{ $t("header.ScriptMenu.scripts") }}</span>
        </div>
        <van-popup
          class="van-popup-right"
          v-model="ScriptMenu"
          position="right"
        >
          <van-list>
            <van-cell
              v-for="group in scriptData"
              :key="group.id"
              @click="openCascader(group.categories)"
            >
              <template #icon>
                <van-image
                  class="script-icon"
                  v-if="group.img_url"
                  :src="
                    require(`./../../assets/images/script/${group.img_url}`)
                  "
                />
              </template>
              <template #title>
                <div class="script-title">{{ group.group_name }}</div>
              </template>
            </van-cell>
          </van-list>
        </van-popup>
      </van-col>
      <!--      cascader menu for script-->
      <van-popup v-model="cascaderVisible" position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title=""
          :placeholder="$t('header.ScriptMenu.select')"
          :options="cascaderOption"
          @close="cascaderVisible = false"
          @finish="onFinish"
          active-color="#1989fa"
        />
      </van-popup>
    </van-row>
  </header>
</template>
<script>
import BackButton from "@/components/template/BackButton";
import goBack from "@/mixins/go-back";
import {
  Cascader,
  Cell,
  Col,
  Icon,
  Image,
  List,
  Popup,
  Radio,
  RadioGroup,
  Row,
} from "vant";
import { eventBus } from "@/plugins/eventBus";
// import Map from "@/components/ai/Map";
import { latLng } from "leaflet/dist/leaflet-src.esm";
import Dialog from "vant/lib/dialog/index";

export default {
  mixins: [goBack],
  name: "AppHeader",
  data() {
    return {
      chlenix: null,
      showMenu: false,
      locationMenu: false,
      infoMenu: false,
      photoMenu: false,
      lockMenu: false,
      shareMenu: false,
      ScriptMenu: false,
      activeNames: [],
      status_sub: this.$store.state.Settings.settings
        ? this.$store.state.Settings.settings.status_sub
        : this.$store.state.Auth.status,
      showMap: false,
      mapCoordinates: null,
      latitude: null,
      longitude: null,
      isDropdownOpen: false,
      cascaderValue: "",
      cascaderScript: "",
      activeGroup: null,
      cascaderVisible: false,
      cascaderOption: "",
      selectChatMenu: false,
      // radio: null,
      paid: false,
      openAiModels: ["dall-e-3", "dall-e-2", "gpt-4", "gpt-3.5-turbo"],
      chatModels: ["gpt-4", "gpt-3.5-turbo", "bard-14"],
      imageModels: ["dall-e-3", "dall-e-2"],
      googleAiModels: ["bard-14"],
    };
  },
  components: {
    BackButton,
    "van-col": Col,
    "van-row": Row,
    "van-icon": Icon,
    "van-popup": Popup,
    "van-cell": Cell,
    "van-cascader": Cascader,
    "van-image": Image,
    "van-list": List,
    "van-radio-group": RadioGroup,
    "van-radio": Radio,
    // Map,
    //"van-uploader": Uploader,
  },
  computed: {
    platform() {
      return this.$store.state.Auth.platform;
    },
    radio: {
      get() {
        let currentModel;
        if (this.$store.state.Auth.currentChat.type === 'image') {
          currentModel = this.$store.state.Auth.currentChat.model + ',' + this.$store.state.Auth.currentChat.imageSize;
        } else {
          currentModel = this.$store.state.Auth.currentChat.model
        }
        return currentModel;
      },
      set(model) {
        if (model.includes(',')) {
          let parts = model.split(',');
          let modelAI = parts[0];
          let imageSize = parts[1];
          console.log("radio split" + parts[0] + " @ " + parts[1]);
          this.$store.commit("Auth/SET_CURRENT_CHAT_MODEL", modelAI);
          this.$store.commit("Auth/SET_CURRENT_IMAGE_SIZE", imageSize);
        } else {
          this.$store.commit("Auth/SET_CURRENT_CHAT_MODEL", model);
        }
      },
    },
    scriptData() {
      return this.$store.state.HeaderScript.script;
    },
    nativeData() {
      return this.$store.state.Auth.nativeData;
    },
    currentChatModel() {
      return this.$store.state.Auth.currentChat.model;
    },
    currentImageSize() {
      let parts = this.$store.state.Auth.currentChat.imageSize.split('.');
      let size = parts[0];
      let HD = parts[1];
      let imageSize;
      if (HD !== undefined) {
        imageSize = size + ' ' + HD;
      } else {
        imageSize = size;
      }
      return imageSize;
    },
    currentChatType() {
      return this.$store.state.Auth.currentChat.type;
    },
    currentPoints() {
      return this.$store.state.Auth.userPoints;
    },
  },
  created() {
    this.nativeSendEvent("accessToken", this.$store.state.Auth.authToken);
    console.log("getEmail HEADER");
    this.$store.dispatch("Settings/getEmail");
  },
  mounted() {
    if (this.$store.state.Auth.currentChat.type === 'image') {
      this.radio = this.$store.state.Auth.currentChat.model + ',' + this.$store.state.Auth.currentChat.imageSize;
    } else {
      this.radio = this.$store.state.Auth.currentChat.model
    }
    this.mapCoordinates = latLng(0, 0);
    this.nativeWaitForEvent("setCoordinates", (coordinates) => {
      this.mapCoordinates = latLng(coordinates.latitude, coordinates.longitude);
      this.latitude = coordinates.latitude;
      this.longitude = coordinates.longitude;
      let geoCoorginate = coordinates.latitude + "," + coordinates.longitude;
      this.$store.commit("GeoMap/SET_LATLNG", geoCoorginate);
      this.$store.dispatch("GeoMap/addGeoInfo");
    });
    this.nativeWaitForEvent("ResponseRewardedInterstitialAd", (data) => {
      console.log("ResponseRewardedInterstitialAd: " + JSON.stringify(data));
      let advertisementPoints;
      advertisementPoints = 30;
      if(data === true) {
        this.$store.commit("Settings/STORE_ADVERTISEMENT_POINTS", advertisementPoints);
        this.$store.dispatch("Settings/storeAdvertisementPoints");
        Dialog.confirm({
          title: this.$t("header.points.title"),
          message: this.$t("header.points.text1") + ' ' + advertisementPoints + ' ' + this.$t("header.points.text2"),
          confirmButtonText: "Ok",
          showCancelButton: false
        });
      }

    });
  },
  methods: {
    handleRadioChange(value) {
      console.log("handleRadioChange:" + JSON.stringify(value));
      let type;
      let parts = value.split(',');
      let modelAI = parts[0];
      switch (modelAI) {
        case "gpt-3.5-turbo":
          type = "text";
          break;
        case "dall-e-2":
          type = "image";
          break;
        case "gpt-4":
          type = "text";
          break;
        case "dall-e-3":
          type = "image";
          break;
        case "bard-14":
          type = "text";
          break;
      }
      this.$store.commit("Auth/SET_CURRENT_CHAT_TYPE", type);
      this.$store.dispatch("Auth/storeAiChat");
    },
    // checkPaid(model) {
    //
    // },
    openCascader(category) {
      this.cascaderOption = category;
      console.log("this.cascaderOption:" + JSON.stringify(this.cascaderOption));
      this.cascaderVisible = true;
    },
    onFinish({ selectedOptions }) {
      this.ScriptMenu = false;
      this.cascaderVisible = false;
      this.cascaderScript = selectedOptions
        .map((option) => option.text)
        .join(" ");
      eventBus.$emit("cascader", this.cascaderScript);
      console.log("onFinish: " + this.cascaderScript);
    },
    selectOption(option) {
      let request =
        this.$t("header.mapMenu.lat") +
        " " +
        this.latitude +
        " " +
        this.$t("header.mapMenu.lng") +
        " " +
        this.longitude +
        " " +
        this.$t("header.mapMenu.show") +
        " " +
        option +
        " " +
        this.$t("header.mapMenu.near");
      eventBus.$emit("mapMenuElSelected", request);
      this.locationMenu = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    toggleMap() {
      this.locationMenu = true;
      this.nativeSendEvent("getLocation");
    },
    async tap() {
      // await this.$store.dispatch("Settings/getSettings").then(() => {
      //   this.status_sub = this.$store.state.Settings.settings.status_sub;
      this.nativeSendEvent("tap");
      // });
    },
    openBurgerMenu() {
      this.showMenu = true;
      this.$store.dispatch("Auth/getUserPoints");
    },
    selectSubMenuItem(item) {
      eventBus.$emit("menuItemSelected", item);
      this.infoMenu = false;
      this.activeNames = [];
    },
    takePhoto() {
      this.nativeSendEvent("camera");
    },
    takeGallery() {
      this.nativeSendEvent("imagePicker", "multiple");
    },
    settings() {
      this.$router.push({ name: "Settings" });
    },
    watchAdvertisement() {
      console.log("watchAdvertisement")
      this.nativeSendEvent("preloadInterstitialRewarded");
    },
    payments() {
      this.$router.push({ name: "StripePayment" });
    },
    authorization() {
      this.$router.push({ name: "AutorizationPage" });
    },
    shareApp() {

      this.nativeSendEvent("socialShare", {
        format: "shareUrl",
        url: "https://wikki.ai/id/" + this.$store.state.Auth.userId,
        text: "https://wikki.ai/id/" + this.$store.state.Auth.userId,
        subject: "Wiki ai app",
      });
      this.nativeWaitForEvent("socialShareStatus", (shareStatus) => {
        console.log(JSON.stringify(shareStatus));
      });
    },
  },
};
</script>

<style scoped>
.header {
  /*position: sticky;*/
  /*top: 0;*/
  width: 100%;
  z-index: 9;
  background-color: #ffffff !important;
}

.top-menu-row {
  margin-top: 1%;
  margin-bottom: 1%;
  display: flex;
  align-items: center;
  /*z-index: 999;*/
}

.top-menu-item {
  width: 100%;
  margin: 1%;
  padding: 1%;
  box-sizing: border-box;
  text-align: center;
}

.burger-menu-row {
  padding-bottom: 1%;
  margin-top: 2%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
}

.burger-menu {
  width: 25%;
  margin: 1%;
  padding: 1%;
  box-sizing: border-box;
  text-align: center;
}

.ai-info-container {
  display: flex;
  align-items: center;
  width: 75%;
}

.image-ai-info {
  width: 12%;
}

.title-ai {
  width: 50%;
  margin-left: 10%;
  font-size: 65%;
}

.title-ai-info-container {
  width: 60%;
  margin-left: 10%;
}

.title-ai-info {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 3%;
  font-size: 90%;
}

.text-ai-info {
  color: #00c8c8;
  text-align: center;
  max-height: 40px;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 3%;
  font-size: 80%;
}

/*@media (max-width: 388px) {*/
/*  .title-ai-info {*/
/*    text-align: center;*/
/*    max-height: 25px;*/
/*    margin-top: 0;*/
/*    margin-bottom: 0;*/
/*    font-size: 13px;*/
/*  }*/

/*  .text-ai-info {*/
/*    color: #00c8c8;*/
/*    text-align: center;*/
/*    max-height: 40px;*/
/*    margin-top: 0;*/
/*    margin-bottom: 0;*/
/*    font-size: 12px;*/
/*  }*/
/*}*/

.image-arrow-right {
  margin-left: 5%;
  width: 12%;
}

.icon-size {
  font-size: 1.4rem;
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #646566;
  font-size: 70%;
  line-height: 100%;
}

.van-popup-left {
  width: fit-content;
  white-space: nowrap;
  height: 100%;
}

.van-popup-top {
  width: 100%;
  white-space: nowrap;
}

.burger-status-title {
  font-size: 100%;
  margin-right: 5px;
  color: #323233;
}

.burger-image {
  width: 12%;
}

.burger-van-cell {
  padding: 8px 10px;
}

.burger-title {
  max-width: 240px;
  margin-top: 3%;
  font-size: 100%;
}

.centered-cell {
  align-items: center;
  text-align: center;
}

.location {
  text-align: right;
  overflow-y: hidden;
  height: 100%;
}

.close-map-icon {
  z-index: 9999;
  margin-right: 10px;
}

.van-cell__value {
  text-align: center;
}

.script-icon {
  width: 30px;
  height: 30px;
  padding-top: 3%;
}

.script-title {
  font-size: 65%;
  margin-top: 5%;
}

.small-text {
  font-size: 65%;
}

.van-collapse-item__title {
  font-size: 65% !important;
}

.van-popup-right {
  max-width: 80%;
  min-width: 50%;
  white-space: pre-line;
  height: 100%;
}

.options-popover-right {
  margin-right: 5%;
}

.menu-icon {
  margin-right: 3%;
}

.account-status-true {
  font-size: 100%;
  color: #00ac52;
}

.account-status-false {
  font-size: 100%;
  color: darkgoldenrod;
}

.wrapper-dropdown {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100% !important;
  max-width: 483px;
  padding: 0px 0px 0px 0px;
  min-height: 44px;
  background: #fff;
  text-align: center;
  color: #1c2028;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selected-display {
  display: grid;
  place-items: center;
}

.arrow {
  display: grid;
  place-items: center;
}

.dropdown {
  position: absolute;
  top: auto;
  bottom: 100%;
  right: 0;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #2b2f3a;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  transform-origin: center top;
  z-index: 10;
}

.wrapper-dropdown.active .dropdown {
  visibility: visible;
  opacity: 1;
}

.dropdown li {
  padding: 10px 20px;
  color: #2b2f3a;
  background-color: #fff;
  transition: background-color 0.3s;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #fff;
  border-radius: 10px;
}

.radio-item {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.radio-item img {
  width: 30px;
  margin-right: 10px;
}

.radio-title {
  font-size: 100%;
}

.radio-subtitle {
  font-size: 80%;
  margin-top: 1%;
  padding-left: 5%;
  display: flex;
  align-items: center;
}

.radio-subtitle-2 {
  font-size: 80%;
  padding-left: 30%;
  display: flex;
  align-items: center;
  color: #323233;
}

.radio-text {
  padding: 5% 0 2% 7%;
  font-size: 80%;
}

/*@media (max-height: 800px) {*/
/*  .burger-menu {*/
/*    width: 25%;*/
/*    margin: 4px;*/
/*    padding: 4px;*/
/*    box-sizing: border-box;*/
/*    text-align: center;*/
/*  }*/

/*  .top-menu-item {*/
/*    width: 33.3%;*/
/*    margin: 4px;*/
/*    padding: 4px;*/
/*    box-sizing: border-box;*/
/*    text-align: center;*/
/*  }*/

/*  .image-arrow-right {*/
/*    margin-left: 5%;*/
/*    width: 25px;*/
/*  }*/

/*  .icon-size {*/
/*    font-size: 1.2rem;*/
/*  }*/

/*  .menu-wrapper {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    color: #646566;*/
/*    font-size: 10px;*/
/*    line-height: 18px;*/
/*  }*/

/*  .image-ai-info {*/
/*    width: 25px;*/
/*  }*/

/*  .title-ai-info {*/
/*    text-align: center;*/
/*    max-height: 25px;*/
/*    margin-top: 0;*/
/*    margin-bottom: 0;*/
/*    font-size: 13px;*/
/*  }*/

/*  .text-ai-info {*/
/*    color: #00c8c8;*/
/*    text-align: center;*/
/*    max-height: 40px;*/
/*    margin-top: 0;*/
/*    margin-bottom: 0;*/
/*    font-size: 11px;*/
/*  }*/
/*}*/
</style>
