import GeoMap from "@/plugins/api/factories/GeoMap";

export const state = {
  latlng: null,
};

export const actions = {
  addGeoInfo({ state }) {
    return new Promise((resolve, reject) => {
      GeoMap.geoInfo(
        state.latlng,
        (response) => {
          console.log("addGeoInfo: " + JSON.stringify(response));
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};

export const mutations = {
  SET_LATLNG(state, latlng) {
    state.latlng = latlng;
  },
};
