<template>
  <div class="payment-page">
    <div class="payment-header">
      <h2>Pay {{ this.paymentSum + '.00€' }}</h2>
    </div>
    <div class="payment-form">
      <div id="card-element"></div>
      <button @click="submitPayment">Pay</button>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import Dialog from "vant/lib/dialog/index";

export default {
  name: "PaymentStripe",
  data() {
    return {
      stripe: null,
      elements: null,
      cardElement: null,
      paymentError: "",
      clientSecret: null,
      paymentSum: null,
    };
  },
  created() {
    // Загрузка Stripe
    this.initializeStripe();
  },
  methods: {
    async initializeStripe() {
      const publicKey = "pk_live_51NM7g1AGXYZcKdTeDZziG9Lh2dC2yuxEUKGEqOenNX9mU0hK1BEsmInf7W5mtu4Z0OlYhhMRZbAJYi7IcWc0Aiv100LfpfDfE9";
      // const publicKey= "pk_test_51NM7g1AGXYZcKdTeLz1hWK1RIjovFGAzQkcaGbfJXCHluaoSAGMb7soH06aeAGcBez0mrUSsn1eBX8KZ3xJjQ6VL00Csz0cgv6";
      try {
        const stripe = await loadStripe(
            publicKey
        );
        this.paymentSum = this.$route.params.points/100;
        let paymentData = {
          amount: this.$route.params.points,
        };
        const response = await this.$store.dispatch(
          "Payment/createPaymentIntentData",
          paymentData
        );
        console.log("response.clientSecret ", JSON.stringify(response.clientSecret));
        const clientSecret = response.clientSecret.client_secret;
        const elements = stripe.elements({ clientSecret });
        const paymentElement = elements.create("payment");
        paymentElement.mount("#card-element");
        this.clientSecret = clientSecret;
        this.cardElement = paymentElement;
        this.stripe = stripe;
        this.elements = elements;
        console.log("this.clientSecret ", JSON.stringify(this.clientSecret));
      } catch (error) {
        console.error("Error initializing Stripe:", error);
      }
    },
    async submitPayment() {
      try {
        if (!this.stripe || !this.elements || !this.cardElement) {
          throw new Error("Stripe elements not initialized.");
        }

        await this.elements.submit();

        let return_url = "https://wiki.itserve.es/payment/success";
        // let return_url = "http://localhost:8080/payment/success";

        this.stripe
          .confirmPayment({
            redirect: "if_required",
            confirmParams: {
              return_url: return_url,
            },
            payment_method: {
              card: this.cardElement,
            },
            clientSecret: this.clientSecret,
            elements: this.elements,
          })
          .then(({ paymentIntent, error }) => {
            if (error) {
              console.log("Payment error:", error);
              this.paymentError = error.message;
              Dialog.alert({
                message: error.message,
                confirmButtonText: "Ok",
              });
            } else {
              this.$store.dispatch(
                "Payment/setSuccessClientPayment",
                paymentIntent
              );
              console.log("Payment successful:", paymentIntent);
              this.$router.push({ name: "SuccessfullyPaid" });
              // Dialog.alert({
              //   message: "CHLENIX",
              //   confirmButtonText: "Ok",
              // });
            }
          })
          .catch((error) => {
            console.error("Error processing payment:", error);
            this.paymentError = error.message;
            Dialog.alert({
              message: error.message,
              confirmButtonText: "Ok",
            });
          });
      } catch (error) {
        console.error("Error processing payment:", error);
        this.paymentError = error.message;
      }
    },
  },
};
</script>

<style scoped>
.payment-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.payment-header {
  margin-bottom: 20px;
  font-size: 24px;
}

.payment-form {
  min-width: 80%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-form button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.payment-error {
  margin-top: 20px;
  color: red;
}
</style>
