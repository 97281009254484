<template>
  <van-row class="add-card-page-wraper">
    <template v-if="paymentUrl">
      <div class="iframe-container">
        <iframe
            :src="paymentUrl"
            allowpaymentrequest
            ref="renderAddCard"
            class="add-card-page"
        ></iframe>
      </div>
    </template>
  </van-row>
</template>
<script>
export default {
  name: "ConfirmPaymentPage",
  computed: {

  },
  data() {
    return {
      paymentUrl: this.$route.params.url,
    };
  },
  created() {
  },
  destroyed() {
  },
  methods: {

  },
};
</script>

<style scoped>
.iframe-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch !important;
  height: calc(100vh - 120px);
}
.iframe-container iframe {
  height: calc(100vh - 120px);
  width: 1px;
  min-width: 100%;
  *width: 100%;
}
.add-card-page {
  border: none !important;
}
.add-card-page-wraper {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}
</style>
