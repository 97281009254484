import { render, staticRenderFns } from "./AppPolicy.vue?vue&type=template&id=75443295&scoped=true"
import script from "./AppPolicy.vue?vue&type=script&lang=js"
export * from "./AppPolicy.vue?vue&type=script&lang=js"
import style0 from "./AppPolicy.vue?vue&type=style&index=0&id=75443295&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75443295",
  null
  
)

export default component.exports