import api from "@/plugins/api/EntryPoint";

export default {
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  prepareAccount(account) {
    console.log("ACCOUNT GET LS: " + JSON.stringify(account));
    return {
      accountNumber: account.ls,
      eic: account.EIC,
      city: account.CITY_TYPE + ", " + account.CITY_NAME,
      building: account.BUD,
      streetName: account.NVUL
        ? this.capitalizeFirstLetter(account.NVUL.toLowerCase())
        : "",
      appartment: account.KVAR === "" ? null : account.KVAR,
      balance: account.SAL_POCH ? account.SAL_POCH : 0,
      ls: account.ls,
    };
  },
  async refreshToken(cb) {
    return await api.get(`auth/refresh-token`, (response) => {
      return cb(response);
    });
  },
  async nativeAuth(nativeData, callBackFn) {
    console.log("CHLENIX FOR BACK nativeAuth: " + JSON.stringify(nativeData));
    return await api.post("userAuth", nativeData, callBackFn)
      .catch((error) => {
        console.log(error);
      });
  },
  googleAuth(nativeData, callBackFn) {
    // console.log("CHLENIX FOR BACK googleAuth: " + JSON.stringify(nativeData));
    return api.post("googleAuth", nativeData, callBackFn);
  },
  appleAuth(nativeData, callBackFn) {
    // console.log("CHLENIX FOR BACK appleAuth: " + JSON.stringify(nativeData));
    return api.post("appleAuth", nativeData, callBackFn);
  },
  deleteUser(cb) {
    return api.post("settings/delete-user", cb);
  },
  guestMode(guest, cb) {
    return api.put("set-guest", { guest: guest }, cb);
  },
  getGuestMode(callBackFn) {
    return api.get("guest", callBackFn);
  },
  getUserPoints(callBackFn) {
    return api.get("get-user-points", callBackFn);
  },
};
