<template>
  <div>
    <!--    <loader :show="showLoader" />-->
    <!--меню выбора языка для переводов-->
    <translate-language-picker
      :TranslateLanguagePicker="TranslateLanguagePicker"
      :TranslateMessage="TranslateMessage"
      @update:TranslateLanguagePicker="updateTranslateLanguagePicker"
    >
    </translate-language-picker>
    <!--deeplink popup-->
    <deeplink-popup
      :deeplinkPopup="deepLinkPopup"
      :deepLinkData="deepLinkData"
    ></deeplink-popup>
    <!--поделиться чекбокс меню-->
    <share-popup
      :shareCheckboxMessage="shareCheckboxMessage"
      :selectedMessages="selectedMessages"
      @update:shareCheckboxMessage="shareCheckboxMessage = $event"
    ></share-popup>
    <!--всплывающее окно-->
    <v-snackbar v-model="showSnackbar" :timeout="snackbarTimeout">
      <div class="snackbar-center">
        <div class="snackbar-message">
          {{ snackbarMessage }}
        </div>
      </div>
    </v-snackbar>
    <!--загрузка фото-->
    <div class="progress-container" v-if="showProgressBar">
      <div class="progress-overlay"></div>
      <van-progress :percentage="uploadProgress" class="progress" />
    </div>
    <!--модальное окно-->
    <template v-if="!showLoader">
      <div
        v-if="selectedMessage"
        v-show="showOptionsModal"
        class="custom-popover-style"
        :class="[
          optionsModalPosition === 'top'
            ? 'options-popover-top'
            : optionsModalPosition === 'bottom'
            ? 'options-popover-bottom'
            : 'options-popover-middle',
        ]"
      >
        <van-cell-group class="custom-van-cell-group-style">
          <van-cell
            v-if="selectedMessage.text"
            :title="$t('gpt.copy')"
            @click="copyMessage(selectedMessage.text)"
          >
            <template #icon>
              <img src="./../../assets/images/copy.svg" alt="My Icon" />
            </template>
          </van-cell>
          <van-cell
            :title="this.$t('gpt.share')"
            icon="share"
            @click="shareMessage(selectedMessage)"
          ></van-cell>
          <!--          <van-cell-->
          <!--            v-if="selectedMessage.text"-->
          <!--            :title="this.$t('gpt.generateImage')"-->
          <!--            icon="photo-o"-->
          <!--            @click="generateModalImage(), loadMessage()"-->
          <!--          ></van-cell>-->
          <van-cell
            v-if="selectedMessage.text"
            :title="this.$t('gpt.edit')"
            icon="edit"
            @click="editMessage(selectedMessage)"
          ></van-cell>
          <van-cell
            v-if="selectedMessage.text"
            :title="this.$t('gpt.repeatRequest')"
            icon="replay"
            @click="handleButtonClickModal(), loadMessage()"
          ></van-cell>
          <van-cell
            v-if="selectedMessage.imageUrl"
            :title="this.$t('gpt.downloadImage')"
            icon="down"
            @click="downloadImage(selectedMessage)"
          ></van-cell>
          <van-cell
            v-if="selectedMessage.text"
            :title="this.$t('gpt.translate')"
            icon="font-o"
            @click="translate(selectedMessage)"
          ></van-cell>
        </van-cell-group>
      </div>
    </template>
    <!--меню для чекбоксов-->
    <div class="custom-menu" v-if="checkbox">
      <van-cell class="custom-menu-element" icon="share" @click="shareMenu">
      </van-cell>
      <van-cell
        class="custom-menu-element"
        icon="delete"
        @click.native="openConfirmationDialog"
      >
      </van-cell>
      <van-cell class="custom-menu-element" icon="cross" @click="closeMenu">
      </van-cell>
    </div>
    <!--диалоговое окно-->
    <div class="chat-container">
      <div
        class="dialogue-box-container"
        :style="{ paddingBottom: dynamicPaddingBottom }"
      >
        <van-pull-refresh
          class="pull-refresh"
          v-model="isLoading"
          @refresh="refreshMessages"
          loading-text="loading..."
          pulling-text="Pull to refresh..."
          loosing-text="Loose to refresh..."
        >
          <div ref="dialogueMessages" @touchmove="handleTouchMove">
            <div
              v-for="message in filteredMessages"
              :key="message.id"
              :class="[
                'dialogue-message',
                message.sender,
                { selected: selectedMessageId === message.id },
              ]"
              :data-is-text="message.isText"
              @touchend="handleTouchEnd"
              @touchstart="handleTouchStart($event, message)"
            >
              <div
                :class="{ active: selectedMessages.includes(message) }"
                class="message-overlay"
              ></div>
              <div
                v-if="checkbox"
                class="message-checkbox"
                @click="toggleMessageSelection(message)"
              >
                <input
                  :checked="isMessageSelected(message)"
                  class="van-checkbox"
                  type="checkbox"
                />
                <label></label>
              </div>
              <van-swipe-cell
                :class="['swipe-cell', { 'with-checkbox': isCheckboxActive() }]"
                :right-width="isCheckboxActive() ? '70%' : '50%'"
                :disabled="shouldDisableSwipeCell"
              >
                <div
                  :ref="`messageContainer_${message.id}`"
                  :data-message-id="message.id"
                  class="swipe-content"
                  @click="handleClick"
                  @click.stop="toggleOptionsModal(message)"
                >
                  <template v-if="message.is_subscribe_request">
                    <p v-if="message.text" class="message-text">
                      {{ $t("gpt.subscribe.text1") }}
                      <a
                        href="#"
                        @click="subscribe(message.is_subscribe_request)"
                      >
                        {{ $t("gpt.subscribe.text2") }}
                      </a>
                      <br />
                      <br />
                      {{ $t("gpt.subscribe.text3") }}
                    </p>
                  </template>
                  <template v-else>
                    <template
                      v-if="showLoader && message.text && message.id === -1"
                    >
                      <v-skeleton-loader type="image" :rounded="true">
                      </v-skeleton-loader>
                    </template>
                    <template v-else-if="message.text && allLoaded && message.newRefresheMessage">
                      <v-skeleton-loader type="image" :rounded="true">
                      </v-skeleton-loader>
                    </template>
                    <template v-else>
                      <div
                        v-if="message.text"
                        :class="{
                          'message-container': true,
                          'additional-bot-style': message.sender === 'bot',
                        }"
                      >
                        <div
                          class="message-sender-info"
                          v-if="
                            message.current_chat_model &&
                            message.current_chat_logo
                          "
                        >
                          <img
                            class="img-sender-info"
                            :src="
                              require(`../../assets/images/${message.current_chat_logo}`)
                            "
                            alt="My Icon"
                          />
                          <p class="text-sender-info">Chat</p>
                          <p class="text-sender-info">
                            {{ message.current_chat_model }}:
                          </p>
                        </div>
                        <p class="message-text">
                          {{ message.text }}
                        </p>
                      </div>
                    </template>
                  </template>
                  <template
                    v-if="showLoader && message.deeplink && message.id === -1"
                  >
                    <v-skeleton-loader type="image" :rounded="true">
                    </v-skeleton-loader>
                  </template>
                  <template v-else-if="message.deeplink && allLoaded && message.newRefresheMessage">
                    <v-skeleton-loader type="image" :rounded="true">
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <div v-if="message.deeplink" class="message-container">
                      <p v-if="message.deeplink" class="message-text">
                        <a :href="message.deeplink" class="deeplink">{{
                          message.deeplink
                        }}</a>
                      </p>
                    </div>
                  </template>
                  <template
                    v-if="showLoader && message.imageUrl && message.id === -1"
                  >
                    <v-skeleton-loader type="image" height="280" :rounded="true">
                    </v-skeleton-loader>
                  </template>
                  <template v-else-if="message.imageUrl && allLoaded && message.newRefresheMessage">
                    <v-skeleton-loader type="image" height="280" :rounded="true">
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <div v-if="message.imageUrl" class="img-container">
                      <div
                        class="message-sender-info"
                        v-if="
                          message.current_chat_model &&
                          message.current_chat_logo
                        "
                      >
                        <img
                          class="img-sender-info"
                          :src="
                            require(`../../assets/images/${message.current_chat_logo}`)
                          "
                          alt="My Icon"
                        />
                        <p class="text-sender-info">Image maker</p>
                        <p class="text-sender-info">
                          {{ message.current_chat_model + ' ' + message.current_image_size}}:
                        </p>
                      </div>
                      <img
                        v-if="message.imageUrl"
                        :src="message.imageUrl"
                        class="message-image"
                      />
                    </div>
                  </template>
                </div>
                <van-cell
                  slot="right"
                  class="delete-button"
                  @click.stop="deleteMessage(message)"
                >
                  <i class="van-icon van-icon-delete"></i>
                </van-cell>
              </van-swipe-cell>
            </div>
          </div>
        </van-pull-refresh>
        <div
          class="overlay"
          v-if="showOptionsModal"
          @click="closeOptionsModal"
        ></div>
      </div>
    </div>
    <!--поле ввода-->
    <div class="input-container">
      <div class="input-field-container">
        <button
          v-if="currentChatType === 'text'"
          class="translate-language-button"
          @click="openLanguagePicker(), tap()"
        >
          <img alt="icon" :src="getFlagIcon(this.languageSelected)" />
        </button>
<!--        <button-->
<!--          :style="{ left: openTextFrameButtonDynamicPositionLeft }"-->
<!--          class="text-frame-button"-->
<!--          @click="openTextFrame(), tap()"-->
<!--        >-->
<!--          <img-->
<!--            class="text-frame-icon"-->
<!--            alt="icon"-->
<!--            src="@/assets/images/text_frame.png"-->
<!--          />-->
<!--        </button>-->
        <textarea
          :style="{ paddingLeft: textareaDynamicPositionPaddingLeft }"
          ref="inputField"
          v-model="inputText"
          autofocus
          class="input-field"
          :placeholder="this.$t('gpt.writeMessage')"
          rows="1"
          type="text"
          @input="autoResize()"
        ></textarea>
        <!--                <button-->
        <!--                  class="generate-image-button"-->
        <!--                  :class="{ disabled: inputText == '' }"-->
        <!--                  @click="generateImage(), tap(), loadMessage()"-->
        <!--                >-->
        <!--                  <img alt="icon" src="@/assets/images/generate_image.png" />-->
        <!--                </button>-->
        <button
          :disabled="isSendButtonDisabled"
          class="send-button"
          @click="handleButtonClick(), tap(), loadMessage()"
        >
          <img alt="icon" src="@/assets/images/send_message.png" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup, Progress, PullRefresh, SwipeCell } from "vant";
import { eventBus } from "@/plugins/eventBus";
import Dialog from "vant/lib/dialog/index";
import SharePopup from "./SharePopup.vue";
import DeeplinkPopup from "./DeeplinkPopup.vue";
import TranslateLanguagePicker from "./TranslateLanguagePicker.vue";
// import Loader from "@/components/content/Loader";
import VueSkeletonLoader from "skeleton-loader-vue";

export default {
  data() {
    return {
      dialogueMessages: [],
      showOptionsModal: false,
      selectedMessage: null,
      selectedMessageId: null,
      optionsModalTransition: "van-fade",
      pageNumber: 1,
      isLoading: false,
      showSnackbar: false,
      snackbarTimeout: 3000,
      snackbarMessage: "",
      selectedItem: "",
      edit: false,
      editData: "",
      optionsModalPosition: null,
      clickYPosition: null,
      touchTimer: null,
      checkbox: false,
      selectedMessages: [],
      showConfirmationDialog: false,
      stripeUrl: null,
      totalBytes: 0,
      currentBytes: 0,
      uploadProgress: 0,
      showProgressBar: false,
      shareCheckboxMessage: false,
      TranslateLanguagePicker: false,
      TranslateMessage: {},
      languageSelected: null,
      input: "",
      chatType: null,
      imageModels: ["dall-e-3", "dall-e-2"],
      messageId: null,
      loadImage: false,
      newRefresheMessage: true,
      firstLoadApp: true,
    };
  },
  components: {
    "van-cell": Cell,
    "van-cell-group": CellGroup,
    "van-swipe-cell": SwipeCell,
    "van-pull-refresh": PullRefresh,
    "van-progress": Progress,
    "share-popup": SharePopup,
    "deeplink-popup": DeeplinkPopup,
    "translate-language-picker": TranslateLanguagePicker,
    "v-skeleton-loader": VueSkeletonLoader,
    // Loader
  },
  computed: {
    textareaDynamicPositionPaddingLeft() {
      if (
        this.$store.state.Auth.currentChat.type === "image" ||
        this.$store.state.Auth.currentChat.type === null
      ) {
        return "35px";
      } else {
        return "35px";
      }
    },
    openTextFrameButtonDynamicPositionLeft() {
      if (
        this.$store.state.Auth.currentChat.type === "image" ||
        this.$store.state.Auth.currentChat.type === null
      ) {
        return "0px";
      } else {
        return "31px";
      }
    },
    dynamicPaddingBottom() {
      const screenHeight = window.innerHeight;
      console.log("dynamicPaddingBottom: " + screenHeight);
      if (
        this.$store.state.Auth.currentChat.model === null ||
        this.imageModels.includes(this.$store.state.Auth.currentChat.model)
      ) {
        return "30%";
      } else {
        return "43%";
      }
    },
    shouldDisableSwipeCell() {
      if (
        this.$store.state.Api.loadingPost === true ||
        this.checkbox === true
      ) {
        return true;
      }
      return false;
    },
    showLoader() {
      if (this.$store.state.Api.loadingPost === false) {
        this.deleteLoadMessage();
      }
      return this.$store.state.Api.loadingPost;
    },
    allLoaded() {
      if (this.$store.state.Api.allLoading === false) {
        this.dialogueMessages.forEach(message => {message.newRefresheMessage = false;});
      }
      return this.$store.state.Api.allLoading;
    },
    nativeData() {
      return this.$store.state.Auth.nativeData;
    },
    deepLinkPopup() {
      return this.$store.state.DeeplinkPopup.deepLinkPopup;
    },
    deepLinkData() {
      return this.$store.state.DeeplinkPopup.deepLinkData;
    },
    currentChatType() {
      return this.$store.state.Auth.currentChat.type;
    },
    inputText: {
      get() {
        return this.$store.state.Ai.requestMessage;
      },
      set(inputText) {
        this.$store.commit("Ai/SET_REQUEST_MESSAGE", inputText);
      },
    },
    filteredMessages() {
      return this.dialogueMessages.filter((message) => {
        // console.log(
        //     "filteredMessages: " +
        //     JSON.stringify(message)
        // );
        if (
          typeof message.text === "string" &&
          message.text.trim() !== "" &&
          message.id
        ) {
          return true;
        } else if (message.imageUrl && !message.text && message.id) {
          return true;
        } else if (
          message.deeplink &&
          !message.imageUrl &&
          !message.text &&
          message.id
        ) {
          return true;
        }
        return false;
      });
    },
    isSendButtonDisabled() {
      return this.inputText.trim() === "";
    },
  },
  created() {
    this.nativeWaitForEvent("cameraRecognizedText", (text) => {
      this.inputText = text;
      this.$nextTick(() => {
        this.autoResize();
      });
      this.$store.commit("Ai/SET_CAMERA_TEXT", text);
    });
    if (this.nativeData.deeplink !== "/") {
      let trimmedUrl = this.nativeData.deeplink.split("wiki.ai://")[1];
      console.log("trimmedUrl: " + JSON.stringify(trimmedUrl));
      this.$store.dispatch("DeeplinkPopup/sendUniqueNumber", trimmedUrl);
    } else {
      console.log("CHLENIX NO deeplink: ");
    }
    document.addEventListener("click", this.closeOptionsModal);
    this.$store.dispatch("Ai/newGetDialogueApiGptPerPage").then((response) => {
      // console.log("CHLENIX displayMessages@@@@@@@" + JSON.stringify(response));
      this.displayMessages(response);
    });
    this.catchPhotoFromNative();
  },
  mounted() {
    eventBus.$on("cascader", (item) => {
      this.inputText = item;
      this.$nextTick(() => {
        this.autoResize();
      });
      console.log("cascader event");
    });
    eventBus.$on("canselTranslate", () => {
      this.languageSelected = null;
    });
    eventBus.$on("TranslateMessage", (item) => {
      this.languageSelected = item.lang;
      this.inputText = item.message;
      console.log("TranslateMessage : " + JSON.stringify(item));
      // this.$store.commit("Ai/SET_REQUEST_MESSAGE_FOR_MODAL", item);
      this.TranslateMessage = {};
      this.sendMessage();
      this.loadMessage();
      this.languageSelected = null;
    });
    eventBus.$on("languageSelected", (item) => {
      this.languageSelected = item;
      console.log("languageSelected: " + JSON.stringify(this.languageSelected));
    });
    eventBus.$on("mapMenuElSelected", (item) => {
      console.log("mapMenuElSelected " + item);
      this.inputText = item;
      this.$nextTick(() => {
        this.autoResize();
      });
    });
    eventBus.$on("menuItemSelected", (item) => {
      this.inputText = item;
      this.$nextTick(() => {
        this.autoResize();
      });
    });
    eventBus.$on("deepLinkData", (item) => {
      this.$store.commit("Ai/SET_DEEP_LINK_DATA", item);
      this.addDeepLinkData();
      this.closeMenu();
    });
    eventBus.$on("saveDeepLinkDataToDialogues", () => {
      console.log(
        "saveDeepLinkDataToDialogues " +
          JSON.stringify(this.$store.state.DeeplinkPopup.deepLinkData)
      );
      this.saveDeepLinkData();
    });
  },
  updated() {
    if(this.firstLoadApp) {
    this.scrollToBottom();
    }
  },
  methods: {
    deleteLoadMessage() {
      const indicesToRemove = this.dialogueMessages.reduce(
        (indices, message, index) => {
          if (message.id === -2 || message.id === -1) {
            indices.push(index);
          }
          return indices;
        },
        []
      );

      if (indicesToRemove.length > 0) {
        indicesToRemove.reverse().forEach((index) => {
          this.dialogueMessages.splice(index, 1);
        });

        // this.scrollToBottom();
      }
    },
    getFlagIcon(locale) {
      if (locale == null) {
        return require(`@/assets/images/language/language-logo.svg`);
      } else {
        return require(`@/assets/images/language/${locale.value}.png`);
      }
    },
    saveDeepLinkData() {
      this.$store
        .dispatch("DeeplinkPopup/saveDeepLinkData")
        .then(() => {
          return this.$store.dispatch("Ai/newGetDialogueApiGptPerPage");
        })
        .then((response) => {
          this.displayMessages(response, true);
        })
        .catch((error) => {
          console.error(error);
        });
      // this.scrollToBottom();
    },
    addDeepLinkData() {
      this.$store.commit("Ai/SET_MODAL", true);
      this.$store
        .dispatch("Ai/deeplink")
        .then((response) => {
          console.log("deeplink " + JSON.stringify(response.data.deepLink));
          this.shareLink(response.data.deepLink);
        })
        .then(() => {
          return this.$store.dispatch("Ai/newGetLastMessageDialogueApiGpt");
        })
        .then((response) => {
          this.displayMessages(response, true);
        })
        .catch((error) => {
          console.error(error);
        });
      // this.scrollToBottom();
    },
    shareLink(link) {
      this.nativeSendEvent("socialShare", {
        subject: "Wiki ai app",
        text: "Shared Message",
        format: "shareUrl",
        url: link,
      });
    },
    isCheckboxActive() {
      return this.checkbox;
    },
    openConfirmationDialog() {
      Dialog.confirm({
        title: this.$t("gpt.deleteMessage"),
        message: this.$t("gpt.message"),
        confirmButtonText: this.$t("gpt.confirm"),
        cancelButtonText: this.$t("gpt.cancel"),
      })
        .then(() => {
          this.deleteMessages();
        })
        .catch(() => {
          console.log("Диалог отменен или закрыт.");
        });
    },
    openTrialPeriodDialog() {
      Dialog.confirm({
        title: this.$t("gpt.points.title"),
        message: this.$t("gpt.points.text"),
        confirmButtonText: "Ok",
        showCancelButton: false
      }).then(() => {
        console.log("openTrialPeriodDialog");
      });
    },
    toggleMessageSelection(message) {
      const index = this.selectedMessages.findIndex(
        (selectedMessage) => selectedMessage === message
      );
      if (index !== -1) {
        this.selectedMessages.splice(index, 1);
      } else {
        this.selectedMessages.push(message);
      }
    },
    isMessageSelected(message) {
      return this.selectedMessages.some(
        (selectedMessage) => selectedMessage === message
      );
    },
    deleteMessages() {
      if (this.selectedMessages.length > 0) {
        const messageIds = this.selectedMessages.map((messageId) => messageId);
        this.$store.commit("Ai/SET_MESSAGE_ID", messageIds);
        this.$store.dispatch("Ai/newDeleteMessage");

        this.dialogueMessages = this.dialogueMessages.filter(
          (message) => !this.selectedMessages.includes(message)
        );

        this.selectedMessages = [];
        this.closeMenu();
      }
    },
    shareMenu() {
      this.shareCheckboxMessage = true;
    },
    closeMenu() {
      this.selectedMessages = [];
      this.checkbox = false;
      this.closeOptionsModal();
    },
    handleTouchStart(event, message) {
      if (this.checkbox !== true) {
        this.touchTimer = setTimeout(() => {
          this.tap();
          this.checkbox = true;
          this.toggleMessageSelection(message);
        }, 1100);
      }
    },
    handleTouchEnd() {
      clearTimeout(this.touchTimer);
    },
    handleClick(event) {
      this.clickYPosition = event.clientY;
    },
    handleTouchMove() {
      this.closeOptionsModal();
      if (this.checkbox === false) {
        this.checkbox = false;
      }
    },
    tap() {
      this.nativeSendEvent("tap");
    },
    autoResize() {
      const input = this.$refs.inputField;
      if (input) {
        input.style.height = "auto";
        input.style.height = input.scrollHeight + "px";
        input.scrollTop = input.scrollHeight;
      }
    },
    hideKeyboard() {
      this.nativeSendEvent("hideKeyboard");
    },
    displayMessages(messages, newMessage = false) {
      messages.forEach((message) => {
        let sender;
        let dialogueMessage;
        let current_chat_model;
        let current_chat_logo;
        let current_image_size;
        if (message.is_user_message == true) {
          sender = "user";
          // current_chat = "you";
        } else {
          sender = "bot";
          current_chat_model = message.current_chat_model;
          current_chat_logo = message.current_chat_logo;
          current_image_size = message.current_image_size;
        }

        dialogueMessage = {
          id: message.id,
          sender: sender,
          is_subscribe_request: message.is_subscribe_request,
          current_chat_model: current_chat_model,
          current_chat_logo: current_chat_logo,
          current_image_size: current_image_size,
          newRefresheMessage: this.newRefresheMessage
        };

        if (message.image_url !== null) {
          dialogueMessage.imageUrl = message.image_url;
        } else if (message.deeplink !== null) {
          dialogueMessage.deeplink = message.deeplink;
          dialogueMessage.isText = true;
        } else {
          dialogueMessage.text = message.message;
          dialogueMessage.isText = true;
        }
        if(newMessage === true) {
          this.dialogueMessages.push(dialogueMessage);
        } else {
          this.dialogueMessages.unshift(dialogueMessage);
        }

      });
      if (this.messageId !== null) {
        this.scrollToMessageLoad(this.messageId);
      } else {
        this.scrollToBottom();
      }
        this.messageId = null;
      this.$store.dispatch("Auth/getUserPoints");
    },
    loadMessage() {
      if (this.showLoader) {
        console.log("showLoader: ");
        const loadingMessage = {
          id: -1,
          sender: "bot",
          isText: true,
          text: "loading...",
        };
        this.dialogueMessages.push(loadingMessage);
        this.scrollToBottom();
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const dialogueMessages = this.$refs.dialogueMessages;
        const lastMessage = dialogueMessages.lastElementChild;
        if (lastMessage) {
              lastMessage.scrollIntoView();
          setTimeout(() => {
            this.firstLoadApp = false;
          }, 2000);
        }
      });

    },
    scrollToBottomMessage(messageId) {
      this.$nextTick(() => {
        const dialogueMessages = this.$refs.dialogueMessages;
        const messageElement = dialogueMessages.querySelector(`[data-message-id="${messageId}"]`);
        if (messageElement) {
          setTimeout(() => {
            messageElement.scrollIntoView();
          }, 200);
        }
      });
    },
    scrollToMessageLoad(messageId) {
      this.$nextTick(() => {
        const dialogueMessages = this.$refs.dialogueMessages;
        const messageElement = dialogueMessages.querySelector(`[data-message-id="${messageId.id}"]`);
        if (messageElement) {
          setTimeout(() => {
            messageElement.scrollIntoView({behavior: "smooth", block: "start"});
          }, 200);
        }
      });
    },
    toggleOptionsModal(message) {
      if (!this.checkbox) {
        if (!message.is_subscribe_request) {
          this.$store.commit("Ai/SET_REQUEST_MESSAGE_FOR_MODAL", message.text);
          const windowHeight = window.innerHeight;
          const topThreshold = 0.4 * windowHeight;
          // const middleThreshold = 0.2 * windowHeight;
          const bottomThreshold = 0.4 * windowHeight;
          if (
            this.selectedMessageId === message.id &&
            this.selectedMessage === message
          ) {
            this.closeOptionsModal();
          } else {
            if (this.clickYPosition < topThreshold) {
              this.openOptionsModal(message, "top");
            } else if (this.clickYPosition > windowHeight - bottomThreshold) {
              this.openOptionsModal(message, "bottom");
            } else {
              this.openOptionsModal(message, "middle");
            }
          }
        }
      } else {
        this.toggleMessageSelection(message);
      }
    },
    openOptionsModal(message, position) {
      this.selectedMessageId = message.id;
      this.selectedMessage = message;
      this.showOptionsModal = true;
      this.optionsModalPosition = position;
    },
    closeOptionsModal() {
      this.selectedMessageId = null;
      this.selectedMessage = null;
      this.showOptionsModal = false;
    },
    copyMessage(content) {
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = content;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
      this.closeOptionsModal();
    },
    handleButtonClick() {
      console.log("handleButtonClick: " + JSON.stringify(this.currentChatType));
      if (this.currentChatType === "text") {
        this.sendMessage();
      } else if (this.currentChatType === "image") {
        this.generateImage();
      } else {
        this.firstMessage();
      }
    },
    handleButtonClickModal() {
      console.log("handleButtonClick: " + JSON.stringify(this.currentChatType));
      if (this.currentChatType === "text") {
        this.sendModalMessage();
      } else if (this.currentChatType === "image") {
        this.generateModalImage();
      }
    },
    firstMessage() {
      this.$store.commit("Ai/SET_FIRST_MESSAGE", this.$t("gpt.firstMessage"));
      this.$store.commit("Ai/SET_MODAL", true);
      this.$store
        .dispatch("Ai/firstMessage")
        .then(() => {
          const responseMessage = this.$store.state.Ai.responseMessage;
          if (responseMessage.success) {
            return this.$store.dispatch("Ai/newGetLastMessageDialogueApiGpt");
          } else {
            this.snackbarMessage = this.$t("gpt.tryLater");
            this.showSnackbar = true;
            setTimeout(() => {
              this.showSnackbar = false;
            }, this.snackbarTimeout);
          }
        })
        .then((response) => {
          this.displayMessages(response, true);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    goToPayment() {
      this.nativeSendEvent("url", this.stripeUrl);
    },
    sendMessage() {
      this.$store.commit("Ai/SET_MODAL", false);
      if (this.edit === true) {
        this.editData.text = this.inputText;
        let data = {
          message_id: this.editData.id,
          text: this.inputText,
        };
        this.$store.commit("Ai/SET_DATA_MESSAGE_FOR_EDIT", data);
        this.$store.dispatch("Ai/newEditDialogueMessage");
        this.inputText = "";
        this.edit = false;
        this.$nextTick(() => {
          this.$refs.inputField.style.height = "auto";
        });
      } else {
        if (this.languageSelected != null) {
          console.log(
            "this.languageSelected: " + JSON.stringify(this.languageSelected)
          );
          const userMessage = {
            id: -2,
            text:
              this.$t("TranslateLanguagePicker.translateTo") +
              " " +
              this.languageSelected.text +
              ": " +
              this.inputText,
            sender: "user",
          };
          this.dialogueMessages.push(userMessage);
          this.$store.commit(
            "Ai/SET_REQUEST_MESSAGE",
            this.$t("TranslateLanguagePicker.translateTo") +
              " " +
              this.languageSelected.text +
              ": " +
              this.inputText
          );
        } else {
          const userMessage = {
            id: -2,
            text: this.inputText,
            sender: "user",
          };
          this.dialogueMessages.push(userMessage);
        }
        this.$store
          .dispatch("Ai/newSendMessageApiGpt")
          .then(() => {
            const responseMessage = this.$store.state.Ai.responseMessage;
            console.log("responseMessage: " + JSON.stringify(responseMessage))
            if (responseMessage.success === true) {
              return this.$store.dispatch("Ai/newGetLastMessageDialogueApiGpt");
            } else if (responseMessage.success === false) {
              this.openTrialPeriodDialog();
              this.$store.commit("Ai/SET_MODAL", true);
              return this.$store.dispatch("Ai/newGetLastMessageDialogueApiGpt");
            } else {
              this.snackbarMessage = this.$t("gpt.tryLater");
              this.showSnackbar = true;
              setTimeout(() => {
                this.showSnackbar = false;
              }, this.snackbarTimeout);
            }
          })
          .then((response) => {
            this.displayMessages(response, true);
          })
          .catch((error) => {
            console.error(error);
          });
        this.inputText = "";
        // this.scrollToBottom();
        this.$nextTick(() => {
          this.$refs.inputField.style.height = "auto";
        });
      }
    },
    sendModalMessage() {
      this.$store.commit("Ai/SET_MODAL", true);
      this.$store
        .dispatch("Ai/newSendModalMessageApiGpt")
        .then(() => {
          const responseMessage = this.$store.state.Ai.responseMessage;
          if (responseMessage.success === true) {
            return this.$store.dispatch("Ai/newGetLastMessageDialogueApiGpt");
          } else if (responseMessage.success === false) {
            this.openTrialPeriodDialog();
          } else {
            this.snackbarMessage = this.$t("gpt.tryLater");
            this.showSnackbar = true;
            setTimeout(() => {
              this.showSnackbar = false;
            }, this.snackbarTimeout);
          }
        })
        .then((response) => {
          this.displayMessages(response, true);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.closeOptionsModal();
        });
    },
    generateImage() {
      this.$store.commit("Ai/SET_MODAL", false);
      if (
        this.$store.state.Ai.requestMessage.length === 0 ||
        /^\s+$/.test(this.$store.state.Ai.requestMessage) === true
      ) {
        this.snackbarMessage = this.$t("gpt.notEmptyField");
        this.showSnackbar = true;
      } else {
        const userMessage = {
          id: -2,
          text: this.inputText,
          sender: "user",
        };
        this.dialogueMessages.push(userMessage);
        this.$store
          .dispatch("Ai/newGenerateImageApiGpt")
          .then(() => {
            const responseImage = this.$store.state.Ai.responseImage;
            console.log("generateImage" + JSON.stringify(responseImage));
            if (responseImage.success === true) {
              return this.$store.dispatch("Ai/newGetLastMessageDialogueApiGpt");
            } else if (responseImage.success === false) {
              this.openTrialPeriodDialog();
              this.$store.commit("Ai/SET_MODAL", true);
              return this.$store.dispatch("Ai/newGetLastMessageDialogueApiGpt");
            } else {
              this.snackbarMessage = this.$t("gpt.tryLater");
              this.showSnackbar = true;
              setTimeout(() => {
                this.showSnackbar = false;
              }, this.snackbarTimeout);
            }
          })
          .then((response) => {
            this.displayMessages(response, true);
          })
          .catch((error) => {
            console.error(error);
          });
        this.inputText = "";
        // this.scrollToBottom();
        this.$nextTick(() => {
          this.$refs.inputField.style.height = "auto";
        });
      }
    },
    generateModalImage() {
      this.$store.commit("Ai/SET_MODAL", true);
      this.$store
        .dispatch("Ai/newGenerateModalImageApiGpt")
        .then(() => {
          const responseImage = this.$store.state.Ai.responseImage;
          if (responseImage.success === true) {
            return this.$store.dispatch("Ai/newGetLastMessageDialogueApiGpt");
          } else if (responseImage.success === false) {
            this.openTrialPeriodDialog();
          } else {
            this.snackbarMessage = this.$t("gpt.tryLater");
            this.showSnackbar = true;
            setTimeout(() => {
              this.showSnackbar = false;
            }, this.snackbarTimeout);
          }
        })
        .then((response) => {
          this.displayMessages(response, true);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.closeOptionsModal();
        });
    },
    catchPhotoFromNative() {
      this.$store.state.Ai.uploadPhoto = [];
      this.nativeWaitForEvent("photo", (file) => {
        if (file.photo.status === "progress") {
          this.showProgressBar = true;
          this.totalBytes = file.photo.totalBytes;
          this.currentBytes = file.photo.currentBytes;
          if (this.currentBytes < this.totalBytes) {
            this.animateUploadProgress(0, 99);
          }
        } else if (file.photo.status === "complete") {
          this.uploadProgress = 100;
          this.$store
            .dispatch("Ai/newGetLastMessageDialogueApiGpt")
            .then((response) => {
              this.displayMessages(response, true);
            })
            .finally(() => {
              this.totalBytes = 0;
              this.currentBytes = 0;
              this.showProgressBar = false;
            });
        } else if (file.photo.status === "error") {
          this.snackbarMessage = this.$t("gpt.photoError");
          this.showSnackbar = true;
        }
        setTimeout(() => {
          this.showSnackbar = false;
        }, this.snackbarTimeout);
      });
    },
    animateUploadProgress(start, end) {
      if (start < end) {
        this.uploadProgress = start;
        start += 1;
        requestAnimationFrame(() => {
          this.animateUploadProgress(start, end);
        });
      } else {
        this.uploadProgress = end;
      }
    },
    shareMessage(content) {
      console.log("shareMessage " + JSON.stringify(content.deeplink));
      if (typeof content.text != "undefined" && content.text !== null) {
        console.log("text ");
        this.nativeSendEvent("socialShare", {
          title: "Shared Message",
          format: "shareText",
          text: content.text,
        });
      } else if (
        typeof content.deeplink != "undefined" &&
        content.deeplink !== null
      ) {
        console.log("deeplink ");
        this.nativeSendEvent("socialShare", {
          title: "Shared link",
          format: "shareUrl",
          url: content.deeplink,
        });
      } else {
        console.log("imageUrl ");
        this.nativeSendEvent("socialShare", {
          title: "Shared Image",
          format: "shareImage",
          src: content.imageUrl,
        });
      }

      this.closeOptionsModal();
    },
    editMessage(message) {
      this.inputText = message.text;
      this.edit = true;
      this.editData = message;
      this.$nextTick(() => {
        this.autoResize();
      });
      this.closeOptionsModal();
    },
    downloadImage(message) {
      this.nativeSendEvent("saveImagePhotoGallery", {
        name:
          "userID: " +
          this.$store.state.Auth.userId +
          ", " +
          "messageID: " +
          message.id,
        src: message.imageUrl,
        format: "png",
      });
      this.closeOptionsModal();
      this.listenDownloadImage();
    },
    openLanguagePicker() {
      this.TranslateMessage = {};
      this.TranslateLanguagePicker = true;
    },
    openTextFrame() {
      this.nativeSendEvent("cameraTextRecognition");
      this.scrollToBottom();
    },
    translate(message) {
      this.TranslateLanguagePicker = true;
      this.TranslateMessage = message;
      this.closeOptionsModal();
    },
    updateTranslateLanguagePicker(value) {
      this.TranslateLanguagePicker = value;
    },
    listenDownloadImage() {
      this.$nextTick(() => {
        this.nativeWaitForEvent("imageDownloadResponse", (responseData) => {
          if (responseData === "image_photo_download_success") {
            this.snackbarMessage = this.$t("gpt.successUploadPhoto");
            this.showSnackbar = true;
          } else {
            this.snackbarMessage = this.$t("gpt.falseUploadPhoto");
            this.showSnackbar = true;
          }
          setTimeout(() => {
            this.showSnackbar = false;
          }, this.snackbarTimeout);
        });
      });
    },
    deleteMessage(messageId) {
      const messageIdArray = [messageId];
      this.$store.commit("Ai/SET_MESSAGE_ID", messageIdArray);
      this.$store.dispatch("Ai/newDeleteMessage");
      const index = this.dialogueMessages.findIndex(
        (message) => message === messageId
      );
      if (index !== -1) {
        this.dialogueMessages.splice(index, 1);
      }
    },
    refreshMessages() {
      this.isLoading = true;
      this.pageNumber++;
      this.$store.commit("Ai/SET_PAGE_NUMBER", this.pageNumber);
      this.$store
        .dispatch("Ai/newGetDialogueApiGptPerPage")
        .then((response) => {
          if (this.dialogueMessages.length > 0) {
            this.messageId = this.dialogueMessages[0];
            // console.log("newGetDialogueApiGptPerPage: " + JSON.stringify(this.messageId));
            // this.dialogueMessages = [];
            this.newRefresheMessage = true;
            this.displayMessages(response, false);
          }
        });
      this.isLoading = false;
    },
    async subscribe(url) {
      this.nativeSendEvent("url", url);
      await this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
@import "../../../node_modules/vant/lib/index.css";
@import "../../../node_modules/vant/lib/image/index.css";
@import "../../../node_modules/vant/lib/progress/index.css";
* {
  box-sizing: border-box;
}

.chat-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("./../../assets/images/background_image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-y: scroll;
  background-color: #f9f9f9;
  color: #333333;
  font-family: "Helvetica", sans-serif;
}

.dialogue-box-container {
  flex: 1;
}

.pull-refresh {
  height: 100%;
}

.sceleton {
  border-radius: 20px;
}

.dialogue-message.bot {
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding: 1% 0 1% 20px;
}

.dialogue-message.user {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 1% 20px 1% 0;
}

.dialogue-message.selected {
  z-index: 996;
}

.img-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;
  background-color: #f2f2f2 !important;
  width: 256px;
}

.message-image {
  border-radius: 5px;
  margin: 10px 5px 10px 5px;
  width: 246px;
}

.message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
}

.additional-bot-style {
  min-width: 23vh;
}

.message-sender-info {
  display: flex;
  align-items: center;
  min-width: 80%;
  margin-left: 5%;
  max-height: 5px;
  margin-top: 4%;
}

.img-sender-info {
  width: 14px;
}

.text-sender-info {
  padding-left: 3%;
  font-size: 10px;
  color: #666;
}

.message-text {
  font-size: 14px;
  margin: 10px 10px 10px 10px;
  color: #000000 !important;
}

/* Стили для контейнера ввода */
.input-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 2% 1% 2% 1%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  z-index: 997;
}

/* Общие стили для обёртки ввода */
.input-field-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

/* Стили для текстового поля */
.input-field {
  flex-grow: 1;
  width: 100%;
  height: 24px;
  max-height: 120px;
  font-size: 16px;
  line-height: 20px;
  border: none;
  background-color: #f2f2f2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
  resize: none;
  padding-right: 35px;
  overflow: auto;
  color: #000000;
}

/* Стили для плейсхолдера */
.input-field::placeholder {
  color: #000;
}

/* Стили для фокуса */
.input-field:focus {
  background-color: #e8e8e8;
  padding-right: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.translate-language-button {
  position: absolute;
  left: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  cursor: pointer;
  z-index: 2;
  bottom: -4px;
}

.text-frame-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  cursor: pointer;
  z-index: 2;
  bottom: -4px;
}

.text-frame-icon {
  width: 22px;
}

.translate-language-button img {
  width: 24px;
  height: 24px;
}

.send-button {
  position: absolute;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  cursor: pointer;
  z-index: 2;
  bottom: -4px;
}

.send-button img {
  width: 24px;
  height: 24px;
  fill: #0088cc;
}

.generate-image-button {
  position: absolute;
  right: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  cursor: pointer;
  z-index: 2;
  bottom: -4px;
}

.disabled img {
  opacity: 0.3;
}

.generate-image-button img {
  width: 24px;
  height: 24px;
}

.custom-popover-style {
  left: 15%;
  position: fixed;
  z-index: 9;
  width: 60%;
}

@media screen and (max-width: 360px) {
  .custom-popover-style {
    left: 14%;
    position: fixed;
    z-index: 9;
    width: 70%;
  }
}

.custom-van-cell-group-style {
  border-radius: 12px;
}

.custom-van-cell-group-style::before,
.custom-van-cell-group-style::after {
  display: none !important;
}

.options-popover-top {
  top: 20%;
}

.options-popover-middle {
  top: 40%;
}

.options-popover-bottom {
  top: 60%;
}

@media screen and (max-width: 360px) {
  .options-popover-top {
    top: 15%;
  }

  .options-popover-middle {
    top: 40%;
  }

  .options-popover-bottom {
    top: 55%;
  }
}

@keyframes optionsModalAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.option:hover {
  background-color: #f5f5f5;
}

.option span {
  margin-left: 8px;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}

.option img {
  width: 18px;
  height: 18px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.snackbar-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: max-content;
  height: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 16px;
  line-height: 1px;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.5s ease;
  animation-name: fadeOut;
  animation: fadeInOut 1.5s ease;
  animation-duration: 1.5s;
  z-index: 999;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.snackbar-message {
  color: #1d1d1d;
  font-size: 14px;
  text-align: center;
}

.snackbar-window-leave-to {
  opacity: 0;
}

.message-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: none;
}

.message-overlay.active {
  display: block;
}

/* Стили для кастомной радио-кнопки */
.message-checkbox {
  position: absolute;
  left: 2%;
  top: 30%;
  border: 2px solid #fff0f0;
  width: 17px;
  height: 17px;
  border-radius: 50%;
}

.message-checkbox label::before {
  content: "";
  position: absolute;
  margin-top: 0.3%;
  left: 3.4%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #00ac52;
  opacity: 0;
  transition: opacity 0.2s;
  background-image: url("./../../assets/images/checkboxIcon.svg");
  background-position: center;
  background-size: 90% 80%;
}

.message-checkbox input[type="checkbox"]:checked + label::before {
  opacity: 1;
}

.message-checkbox .van-checkbox {
  display: none;
}

.swipe-cell {
  max-width: 70%;
}

.swipe-cell.with-checkbox {
  margin-left: 20px;
}

/* Свои стили для меню */
.custom-menu {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  z-index: 9;
}

.custom-menu-element {
  justify-content: center;
}

.sub-delimeter {
  border-top: 1px dotted;
}

.progress-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.progress-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.progress {
  width: 60%;
  transition: width 3s;
}

a.deeplink,
a.deeplink:visited,
a.deeplink:active {
  text-decoration: underline;
  color: #0b0080;
}
</style>
