<template>
  <div>
    <!--    <loader :show="showLoader" />-->
    <template v-if="nativeData.agreement === false">
      <app-policy />
    </template>
    <template v-else-if="hiddenContent === true">
      <app-content />
      <!--      <div class="notoken-box itserve-center" style="z-index: 999999">-->
      <!--                         {{ nativeData }}-->
      <!--                       </div>-->
    </template>
    <!--    <template-->
    <!--        v-else-if="-->
    <!--        guestMode === 1 &&-->
    <!--        nativeData.google_id_token.length === 0 &&-->
    <!--        nativeData.apple_user_id.length === 0-->
    <!--      "-->
    <!--    >-->
    <!--      <div class="itserve-center">-->
    <!--        <AutorizationPage />-->
    <!--        <van-col class="itserve-center mb-15" span="24">-->
    <!--          <p>-->
    <!--            <label-->
    <!--                @click="guest"-->
    <!--            >{{ $t("buttons.skip") }}</label>-->
    <!--          </p>-->
    <!--        </van-col>-->
    <!--      </div>-->
    <!--    </template>-->
    <template v-else-if="authToken !== null && authToken !== undefined">
      <app-header />
      <app-content />
      <!--                       <div style="z-index: 9999" class="notoken-box itserve-center">-->
      <!--                         {{ nativeData }}-->
      <!--                       </div>-->
      <!--      <app-footer />-->
    </template>
    <!--    <template v-else>-->
    <!--      <div-->
    <!--          class="itserve-center height-max-size"-->
    <!--      >-->
    <!--      <div style="margin: 70% auto;"></div>-->
    <!--        <icon-logo/>-->
    <!--      </div>-->
    <!--    </template>-->
  </div>
</template>
<script>
import appBridge from "@/mixins/app-bridge";
import AppPolicy from "@/components/template/AppPolicy";
import AppHeader from "@/components/template/AppHeader";
// import AppFooter from "@/components/template/AppFooter";
import AppContent from "@/components/template/AppContent";
import goBack from "@/mixins/go-back";
// import Loader from "@/components/content/Loader";

// import AutorizationPage from "@/views/autorization/AutorizationPage";
// import IconLogo from "./components/icons/IconLogo.vue";
// import ItserveRoute from "@/components/content/ItserveRoute";
export default {
  name: "App",
  data() {
    return {
    };
  },
  components: {
    // AutorizationPage,
    // ItserveRoute,
    // Loader,
    AppPolicy,
    AppHeader,
    // AppFooter,
    AppContent,
    // IconLogo,
  },
  mixins: [appBridge, goBack],
  beforeMount() {
    //document.body.classList.add("app-theme-dark");
  },
  created() {
    this.nativeWaitForEvent("authToken", (authData) => {
      console.log("authToken: " + JSON.stringify(authData));
      if (authData.agreement == true) {
        this.nativeSendEvent("preloadInterstitial");
      }
      this.$store.dispatch("Auth/authenticate", authData);
    });
    this.nativeWaitForEvent("authTokenListen", (authData) => {
      console.log("authTokenListen: " + JSON.stringify(authData));
      if (authData.google_access_token.length > 0) {
        this.$store
          .dispatch("Auth/authenticateListenGoogle", authData)
          .then(() => {
            setTimeout(() => {
              this.goBack();
            }, 1300);
          });
      }
      if (authData.apple_user_id.length > 0) {
        this.$store
          .dispatch("Auth/authenticateListenApple", authData)
          .then(() => {
            setTimeout(() => {
              this.goBack();
            }, 100);
          });
      }
    });

    // if(this.nativeData.deeplink !== "/"){
    //   let trimmedUrl = this.nativeData.deeplink.substring(this.nativeData.deeplink.indexOf("ai/") + 3);
    //   console.log("trimmedUrl: " + JSON.stringify(trimmedUrl))
    //   this.$store.dispatch("DeeplinkPopup/sendUniqueNumber", trimmedUrl);
    // } else {
    //   console.log("CHLENIX NO deeplink: ")
    // }

    // let authData = {
    //   uid: "SUOQLZR2O6YtkPkwLPrScuGPFrqPOLGOR",
    //   firebase_token:
    //     "epIJQeeRrEqQiLW6kH-0LK:APA91bGIB375gUuf1YhucWHXWBeP_QaSyjxX9mCJueByhF1fAtOrmjfWlFEu4qcwzEb7KYfdv0YPhHtL3fLkZzf0W12KtP2ZY-QUoV6JUSMgoqtr_a42QLi6zoltyn3EXO_q1oQjc1Gq",
    //   themeMode: "ns-dark",
    //   lang: "en",
    //   google_id_token: "",
    //   google_access_token: "",
    //   platform: "ios",
    //   apple_user_id: "",
    //   agreement: true,
    //   deeplink: "/",
    //   // deeplink: "wiki.ai://65eadedf73945"
    // };
    // this.$store.dispatch("Auth/authenticate", authData);

    this.nativeWaitForEvent("backButton", () => {
      if (this.$route.name === "Home") {
        this.nativeSendEvent("setPageLevel", { level: 0 });
      } else {
        this.nativeSendEvent("setPageLevel", { level: 1 });
        this.goBack();
      }
    });
    // this.nativeSendEvent("h ideKeyboard");
    this.nativeWaitForEvent("deepLink", (deepLinkData) => {
      this.$router.push(deepLinkData);
    });
    this.nativeWaitForEvent("appCollapsed", () => {
      this.nativeSendEvent("disableFlashlight");
    });
  },
  computed: {
    showLoader() {
      return this.$store.state.Api.loading;
    },
    flashlightEnabled() {
      return this.$store.state.Flashlight.flashlightEnabled;
    },
    locale() {
      return this.$store.state.Auth.locale;
    },
    appTheme() {
      return this.$store.state.Auth.appTheme;
    },
    authToken() {
      console.log("CHLENIX authToken: " + this.$store.state.Auth.authToken);
      return this.$store.state.Auth.authToken;
    },
    nativeData() {
      return this.$store.state.Auth.nativeData;
    },
    guestMode() {
      return this.$store.state.Auth.guest;
    },
    hiddenContent() {
      return this.$store.state.Auth.hidden;
    },
  },
  watch: {
    authToken() {
      this.$store.dispatch("Auth/getGuest");
      this.$store.dispatch("Auth/setupUserSettings");
    },
    locale(newLocale) {
      this.$i18n.locale = newLocale;
    },
  },
  methods: {
    confirm() {
      this.nativeSendEvent("confirmAgreenment", true);
      this.$store.state.Auth.nativeData.agreement = true;
      this.$store.commit("Settings/AGREEMENT", false);
    },
    guest() {
      this.nativeSendEvent("preloadInterstitial");
      this.$store.commit("Auth/SET_GUEST", true);
      // this.$store.dispatch("Auth/storeGuest");
      // this.$router.push({
      //   name: "Home",
      // });
    },
    googleSign() {
      this.nativeSendEvent("googleSignin");
    },
    appleSign() {
      this.nativeSendEvent("appleSignin");
    },
    ofertaPage() {
      this.$store.commit("Auth/SET_HIDDEN", true);
      this.$store.state.Auth.nativeData.agreement = true;
      this.$store.commit("Settings/AGREEMENT", true);
      this.$router.push({
        name: "OfertaPage",
        params: this.$route.params,
      });
    },
    policyPage() {
      this.$store.commit("Auth/SET_HIDDEN", true);
      this.$store.state.Auth.nativeData.agreement = true;
      this.$store.commit("Settings/AGREEMENT", true);
      this.$router.push({
        name: "PolicyPage",
        params: this.$route.params,
      });
    },
  },
};
</script>
<style>
@import "assets/template.scss";
@import "assets/app.scss";
@import "assets/media.scss";
</style>
