<template>
<!--  <van-row class="page-wrapper" justify="center">-->
<!--    <van-col class="itserve-center" span="24">-->
      <gpt></gpt>
<!--    </van-col>-->
<!--  </van-row>-->
</template>
<script>

import Gpt from "@/components/ai/Gpt";
export default {
  name: "Home",
  components: {
    Gpt
  },
  computed: {
  },
  methods: {
    putIndications() {
      this.nativeSendEvent("url", process.env.VUE_APP_INDICATORS);
    },
    putShutdowns() {
      this.nativeSendEvent("url", process.env.VUE_APP_SHUTDOWN)
    },
    socialShare() {
      this.nativeSendEvent("socialShare", {
        text: this.$t("settings.title") + " " + process.env.VUE_APP_SOCIAL_SHARE,
        subject: this.$t("settings.subject"),
      });
    }

  },
};
</script>
<style scoped>
@import "../../node_modules/vant/lib/notice-bar/index.css";
@import "../../node_modules/vant/lib/list/index.css";
.home-accounts-list {
  margin-bottom: 15px;
}
.home-page {
  background-color: #fff;
  padding-bottom: 15px;
  flex-grow: 1;
}
.main-page-account {
  padding-bottom: 45px;
}
</style>
<style>
.home-accounts-list .home-account {
  border-radius: 10px;
  max-width: 357px;
  -webkit-box-shadow: 0px 2px 3px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 2px 3px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 2px 3px 2px rgba(34, 60, 80, 0.2);
}
</style>
