import Ai from "@/plugins/api/factories/Ai";
import store from "@/store/index";

export const state = {
  firstMessage: "",
  uploadPhoto: [],
  responseMessage: "",
  requestMessage: "",
  responseImage: null,
  requestMessageForModal: {
    message: null,
    modal: true,
  },
  responseMessageForHeaderMenu: null,
  requestMessageForHeaderMenu: {
    message: null,
    modal: true,
  },
  dialogue: [],
  lastMessage: "",
  messageId: null,
  pageNumber: 1,
  dataMessageForEdit: "",
  deepLinkData: [],
  cameraText: null,
  allLoaded: true,
  modal: false,
};

export const actions = {
  deeplink({ state }) {
    store.commit("Api/showLoaderPost");
    return new Promise((resolve, reject) => {
      Ai.addDeepLinkData(
        state.deepLinkData,
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  firstMessage({ state, commit }) {
    store.commit("Api/showLoaderPost");
    console.log("firstMessage" + JSON.stringify(state.firstMessage))
    return new Promise((resolve, reject) => {
      Ai.firstMessage(
          state.firstMessage,
          (response) => {
            resolve(response);
            console.log("firstMessage response" + JSON.stringify(response))
            commit("SET_RESPONSE_MESSAGE", response);
          },
          (error) => {
            reject(error);
          }
      );
    });
  },
  newSendMessageApiGpt({ state, commit }) {
    console.log("newSendMessageApiGpt" + JSON.stringify(state.requestMessage))
    store.commit("Api/showLoaderPost");
    return new Promise((resolve, reject) => {
      Ai.sendMessageApiGpt(
        state.requestMessage,
        state.pageNumber,
        (response) => {
          resolve(response);
          commit("SET_RESPONSE_MESSAGE", response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  newSendModalMessageApiGpt({ state, commit }) {
    store.commit("Api/showLoaderPost");
    return new Promise((resolve, reject) => {
      Ai.sendMessageApiGpt(
        state.requestMessageForModal,
        state.pageNumber,
        (response) => {
          resolve(response);
          commit("SET_RESPONSE_MESSAGE", response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  newSendMessageHeaderMenuApiGpt({ state, commit }) {
    store.commit("Api/showLoaderPost");
    return new Promise((resolve, reject) => {
      Ai.sendMessageApiGpt(
        state.requestMessageForHeaderMenu,
        state.pageNumber,
        (response) => {
          resolve(response.data);
          commit("SET_RESPONSE_MESSAGE_FOR_HEADER_MENU", response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  newGenerateModalImageApiGpt({ state, commit }) {
    store.commit("Api/showLoaderPost");
    return new Promise((resolve, reject) => {
      Ai.generateImageApiGpt(
        state.requestMessageForModal,
        (response) => {
          resolve(response);
          commit("SET_RESPONSE_IMAGE", response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  newGenerateImageApiGpt({ state, commit }) {
    store.commit("Api/showLoaderPost");
    return new Promise((resolve, reject) => {
      Ai.generateImageApiGpt(
        state.requestMessage,
        (response) => {
          resolve(response);
          commit("SET_RESPONSE_IMAGE", response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  newGetDialogueApiGpt({ commit }) {
    return new Promise((resolve, reject) => {
      Ai.getDialogueApiGpt(
        (response) => {
          resolve(response.data);
          commit("DIALOGUE", response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  newGetLastMessageDialogueApiGpt({ state, commit }) {
    store.commit("Api/showLoaderPost");
    return new Promise((resolve, reject) => {
      Ai.getLastMessageDialogueApiGpt(
          state.modal,
          (response) => {
            resolve(response.data);
            commit("LAST_MESSAGE", response.data);
          },
          (error) => {
            reject(error);
          }
      );
    });
  },
  newGetDialogueApiGptPerPage({ state, commit }) {
    store.commit("Api/showAllLoading");
    return new Promise((resolve, reject) => {
      Ai.getDialogueApiGptPerPage(
        state.pageNumber,
        (response) => {
          resolve(response.data);
          commit("SET_ALL_LOADED", false);
          commit("DIALOGUE", response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  newDeleteMessage({ state }) {
    return new Promise((resolve, reject) => {
      Ai.deleteMessage(
        state.messageId,
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  newEditDialogueMessage({ state }) {
    return new Promise((resolve, reject) => {
      Ai.editDialogueMessage(
        state.dataMessageForEdit,
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};

export const mutations = {
  SET_FIRST_MESSAGE(state, firstMessage) {
    state.firstMessage = firstMessage;
  },
  SET_DEEP_LINK_DATA(state, deepLinkData) {
    state.deepLinkData = deepLinkData;
  },
  SET_RESPONSE_MESSAGE(state, message) {
    state.responseMessage = message;
  },
  SET_REQUEST_MESSAGE(state, message) {
    state.requestMessage = message;
  },
  SET_RESPONSE_IMAGE(state, image) {
    state.responseImage = image;
  },
  SET_REQUEST_MESSAGE_FOR_MODAL(state, modalMessage) {
    state.requestMessageForModal.message = modalMessage;
  },
  SET_RESPONSE_MESSAGE_FOR_HEADER_MENU(state, message) {
    state.responseMessageForHeaderMenu = message;
  },
  SET_REQUEST_MESSAGE_FOR_HEADER_MENU(state, message) {
    state.requestMessageForHeaderMenu.message = message;
  },
  DIALOGUE(state, dialogue) {
    state.dialogue = dialogue;
  },
  LAST_MESSAGE(state, lastMessage) {
    state.lastMessage = lastMessage;
  },
  SET_MESSAGE_ID(state, messageId) {
    state.messageId = messageId;
  },
  SET_PAGE_NUMBER(state, pageNumber) {
    state.pageNumber = pageNumber;
  },
  SET_DATA_MESSAGE_FOR_EDIT(state, dataMessageForEdit) {
    state.dataMessageForEdit = dataMessageForEdit;
  },
  SET_CAMERA_TEXT(state, cameraText) {
    state.cameraText = cameraText;
  },
  SET_ALL_LOADED(state, allLoaded) {
    state.allLoaded = allLoaded;
  },
  SET_MODAL(state, modal) {
    state.modal = modal;
  },
};
