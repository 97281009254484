import api from "@/plugins/api/EntryPoint";

export default {
  getSettings(callBackFn) {
    api.get(`get-user-info`, callBackFn);
  },
  storeSettings(settings) {
    return api.post(`settings/update`, settings);
  },
  storeEmail(email) {
    return api.post(`set-user-email`, { email });
  },
  getEmail(callBackFn) {
    api.get(`get-user-email`, callBackFn);
  },
  storePhone(phone) {
    return api.post(`settings/add-phone`, { phone });
  },
  async getPhone(callBackFn) {
    await api.get(`settings/phone`, callBackFn);
  },
  putLanguage(language) {
    return api.put("settings/put-language", { language });
  },
  setTheme(theme) {
    return api.put("settings/set-theme", { theme });
  },
  getTheme(callBackFn) {
    return api.get(`settings/theme`, callBackFn);
  },
  getLanguage(callBackFn) {
    return api.get(`settings/language`, callBackFn);
  },
  storeAdvertisementPoints(points) {
    return api.post(`advertisement-points`, { points });
  },
};
