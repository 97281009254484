<template>
  <van-row class="page-wrapper setting-main-page mt-50">
    <van-col span="24" class="itserve-center flex-display-fix">
      <router-link :to="{ name: 'DeleteUser' }">
        <settings-block>
          <template v-slot:icon>
            <user-icon />
          </template>
          <template v-slot:title> {{ $t("settings.user") }}</template>
          <template v-slot:content> {{ $t("settings.userSettings") }}</template>
        </settings-block>
      </router-link>
      <router-link :to="{ name: 'OfertaPage' }">
        <settings-block>
          <template v-slot:icon>
            <receipt-icon />
          </template>
          <template v-slot:title> {{ $t("settings.oferta") }} </template>
        </settings-block>
      </router-link>
      <router-link :to="{ name: 'PolicyPage' }">
        <settings-block>
          <template v-slot:icon>
            <receipt-icon />
          </template>
          <template v-slot:title> {{ $t("settings.policy") }} </template>
        </settings-block>
      </router-link>
      <template v-if="themeReady">
        <div style="margin: auto" class="theme_checkbox">
          <CheckBoxIOS />
        </div>
      </template>
    </van-col>
    <van-col
      span="24"
      class="itserve-center bottom-content-grow flex-display-fix"
    >
<!--      <template v-if="settings !== null">-->
        <div class="place-content-center">
          <van-form>
            <itserve-language-picker v-model="language" />
            <!--            <info-message class="apply-loader settings-message">-->
            <!--              {{ $t("settings.toReceiveReceipt") }}-->
            <!--            </info-message>-->
            <!--            <itserve-input-->
            <!--              v-model="name"-->
            <!--              type="text"-->
            <!--              class="apply-loader fl-upper"-->
            <!--              :placeholder="$t('settings.name')"-->
            <!--              name="name"-->
            <!--            />-->
            <!--            <itserve-input-->
            <!--              v-model="surname"-->
            <!--              type="text"-->
            <!--              class="apply-loader fl-upper"-->
            <!--              :placeholder="$t('settings.surname')"-->
            <!--              name="surname"-->
            <!--            />-->
            <itserve-input
              v-model="email"
              type="text"
              class="apply-loader"
              :placeholder="$t('settings.email')"
              name="email"
            />
          </van-form>
          <itserve-button
            type="primary"
            class="itserve-round-btn itserve-button-wide mb-25 apply-loader"
            :value="$t('buttons.saveChanges')"
            @click="storeEmail"
          ></itserve-button>
        </div>
        <simple-dialog
          v-model="showDialog"
          :success="true"
          :title="this.$t('settings.successMessage')"
        />
<!--      </template>-->
    </van-col>
  </van-row>
</template>
<script>
import Form from "vant/lib/form/index";
import ItserveInput from "@/components/forms/ItserveInput";
import ItserveButton from "@/components/forms/ItserveButton";
import ItserveLanguagePicker from "@/components/forms/ItserveLanguagePicker";
import SettingsBlock from "@/components/content/SettingsBlock";
import CheckBoxIOS from "@/components/content/CheckBoxIOS";
// import CheckIcon from "vue-feather-icons/icons/CheckIcon";
import UserIcon from "vue-feather-icons/icons/UserIcon";
import "vue-awesome/icons/save";
import SimpleDialog from "@/components/content/SimpleDialog";
// import InfoMessage from "@/components/content/InfoMessage";
// import DeveloperBoardIcon from "vue-material-design-icons/DeveloperBoard";
import ReceiptIcon from "vue-material-design-icons/Receipt";
// import HeartIcon from "vue-material-design-icons/Heart";
export default {
  name: "Index",
  components: {
    // InfoMessage,
    SimpleDialog,
    SettingsBlock,
    ItserveLanguagePicker,
    ItserveButton,
    ItserveInput,
    VanForm: Form,
    // CheckIcon,
    UserIcon,
    CheckBoxIOS,
    // DeveloperBoardIcon,
    ReceiptIcon,
    // HeartIcon,
  },
  computed: {
    settings() {
      return this.$store.state.Settings.settings;
    },
    surname: {
      get() {
        return this.$store.state.Settings.settings.surname;
      },
      set(surname) {
        if (surname) {
          surname = surname.charAt(0).toUpperCase() + surname.slice(1);
        }
        this.$store.commit("Settings/UPDATE_SURNAME", surname);
      },
    },
    name: {
      get() {
        return this.$store.state.Settings.settings.name;
      },
      set(name) {
        if (name) {
          name = name.charAt(0).toUpperCase() + name.slice(1);
        }
        console.log("SET NAME");
        this.$store.commit("Settings/UPDATE_NAME", name);
      },
    },
    language: {
      get() {
        return this.$store.state.Auth.locale;
      },
      set(language) {
        this.$store.commit("Auth/SET_LOCALE", language);
        this.$store.dispatch("Settings/putLanguage", language);
        this.$store.commit("HeaderScript/SET_LANG", language);
        this.$store.dispatch("HeaderScript/allScript", language);
      },
    },
    email: {
      get() {
        return this.$store.state.Settings.email;
      },
      set(email) {
        this.$store.commit("Settings/EMAIL", email);
      },
    },
    theme: {
      get() {
        return this.$store.state.Auth.appTheme;
      },
    },
    themeReady: {
      get() {
        return this.$store.state.Auth.themeReady;
      },
    },
  },
  created() {
    console.log("getEmail SETTINGS");
    this.$store.dispatch("Settings/getEmail");
  },
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    changeSettings() {
      this.$store.dispatch("Settings/storeSettings").then(async (res) => {
        if (res) {
          this.showDialog = true;
        }
      });
    },
    storeEmail() {
      this.$store.dispatch("Settings/storeEmail").then(async (res) => {
        if (res) {
          this.showDialog = true;
        }
      });
    },
  },
};
</script>
<style>
.settings-message .info-content {
  text-align: center !important;
}
</style>
<style scoped>
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
.border {
  border-radius: 15px 15px 15px 15px;
}

.theme_checkbox {
  background-color: #fff;
  width: 100%;
  color: #0b0b0b;
  border-bottom: 1px solid #918dab;
  font-family: Arial, serif !important;
}
</style>
