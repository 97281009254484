<template>
  <van-row justify="center" class="setting-main-page">
    <van-col span="24" class="itserve-center flex-display-fix">
      <Stripe />
    </van-col>
  </van-row>
</template>
<script>

import Stripe from "@/components/payments/Stripe";
export default {
  name: "StripePayment",
  data() {
    return {

    };
  },
  components: {
    Stripe,

  },
  computed: {

  },
  created() {
    console.log("getEmail СТРАЙП");
    this.$store.dispatch("Settings/getEmail");
  },
  methods: {

  },
};
</script>

<style scoped>
@import "../../../node_modules/vant/lib/collapse-item/index.css";


</style>
<style>
.van-collapse-item__content {
  color: #918dab;
  font-size: 14px;
  padding-left: 30px !important;
}
</style>
