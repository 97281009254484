import HeaderScript from "@/plugins/api/factories/HeaderScript";

export const state = {
  lang: null,
  script: null,
};

export const actions = {
  allScript({ commit, state }) {
    return new Promise((resolve, reject) => {
      HeaderScript.getAllScript(
        state.lang,
        (response) => {
          resolve(response);
          commit("SET_SCRIPT", response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};

export const mutations = {
  SET_LANG(state, lang) {
    state.lang = lang;
  },
  SET_SCRIPT(state, script) {
    state.script = script;
  },
};
