import Vue from "vue";
import VueRouter from "vue-router";
import "@/plugins/nativescript-webview-interface.js";
import Home from "../views/Home.vue";
import AutorizationPage from "../views/autorization/AutorizationPage";
import OfertaPage from "../views/autorization/OfertaPage";
import PolicyPage from "../views/autorization/PolicyPage";
import Settings from "../views/settings/Index";
import DeleteUser from "../views/settings/DeleteUser";
import Faq from "../views/faq/Index";
import Notifications from "../views/notifications/Index";
import StripePayment from "../views/settings/StripePayment";
import Pay from "../views/account/payment/Pay";
import SuccessPay from "../views/account/payment/callbacks/payment/Success";
import CancelPay from "../views/account/payment/callbacks/payment/Cancel";
import PaymentPage from '../views/stripe/PaymentPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home", //Done
    component: Home,
  },
  {
    path: "/autorization",
    name: "AutorizationPage",
    component: AutorizationPage,
    meta: {
      backButton: ["Home"],
    },
  },
  {
    path: "/oferta",
    name: "OfertaPage",
    component: OfertaPage,
    meta: {
      backButton: ["Home"],
    },
  },
  {
    path: "/policy",
    name: "PolicyPage",
    component: PolicyPage,
    meta: {
      backButton: ["Home"],
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      backButton: [
        "Home",
        "Account",
        "RegionalEnergy",
        "SendIndicationsPhoto"
      ],
    },
  },
  {
    path: "/delete-user",
    name: "DeleteUser",
    component: DeleteUser,
    meta: {
      backButton: ["Settings"],
    },
  },
  {
    path: "/stripe-payment",
    name: "StripePayment",
    component: StripePayment,
    meta: {
      backButton: ["Home"],
    },
  },
  {
    path: "/stripe-payment/pay/:url",
    name: "Pay",
    component: Pay,
    meta: {
      backButton: ["StripePayment"],
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: Faq,
  },
  {
    path: "/notifications/:account?",
    name: "Notifications",
    component: Notifications,
    meta: {
      backButton: [
        "Home",
        "Account",
        "RegionalEnergy",
        "SendIndicationsPhoto",
        "Settings",
        "PaymentCards"
      ],
    },
  },
  {
    path: "/payment/success",
    name: "SuccessfullyPaid",
    component: SuccessPay,
    meta: {
      backButton: ["Home"],
    },
  },
  {
    path: "/payment/cancel",
    name: "CancelPayment",
    component: CancelPay,
    meta: {
      backButton: ["Home"],
    },
  },
  {
    path: '/payment-stripe',
    name: 'PaymentStripe',
    component: PaymentPage,
    meta: {
      backButton: ["StripePayment"],
    },
  },
  {
    path: "*",
    redirect: { name: "Home" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.name !== null) {
    if (
        from.name === "SendIndications" &&
        router.app.$store.state.Flashlight.flashlightEnabled
    ) {
      router.app.$store.dispatch("Flashlight/disableFlashlight");
    }

    let params = Object.keys(from.params).length ? from.params : null;
    router.app.$store.commit("BackButton/SET_PREVIOUS_ROUTE", {
      name: from.name,
      params,
    });
    router.app.$store.commit("BackButton/SET_CURRENT_ROUTE", to.name);
  } else {
    router.app.$store.commit("BackButton/SET_PREVIOUS_ROUTE", {
      name: "Home",
    });
  }
  next();
});

export default router;
