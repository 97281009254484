<template>
  <van-row justify="center" class="page-wrapper setting-main-page">
    <van-col span="24" class="itserve-center flex-display-fix">
      <div v-if="email !== null">
        <div class="ai-price-container">
          <div class="ai-item">
            <img
              class="ai-image"
              src="../../assets/images/open_ai_logo.png"
              alt="My Icon"
            />
            <div class="ai-title">OPEN AI</div>
          </div>
          <div class="ai-price">
            <div>
              <div class="points-title">Tokens</div>
              <div class="price-label-title">Cost</div>
            </div>
            <div @click="payStripe( 100)">
              <div class="points">100</div>
              <div class="price-label">1€</div>
            </div>
            <div @click="payStripe( 300)">
              <div class="points">300</div>
              <div class="price-label">3€</div>
            </div>
            <div @click="payStripe(1000)">
              <div class="points">1000</div>
              <div class="price-label">10€</div>
            </div>
            <div @click="payStripe(5000)">
              <div class="points">5000</div>
              <div class="price-label">50€</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <router-link :to="{ name: 'Settings' }">
          <settings-block>
            <template v-slot:icon>
              <email-icon />
            </template>
            <template v-slot:title> {{ $t("settings.email") }}</template>
            <template v-slot:content> Need to add email first</template>
          </settings-block>
        </router-link>
      </div>
    </van-col>
  </van-row>
</template>

<script>
// import { StripeCheckout } from '@vue-stripe/vue-stripe';
import SettingsBlock from "@/components/content/SettingsBlock";
import EmailIcon from "vue-material-design-icons/Email";
import goBack from "@/mixins/go-back";

export default {
  components: {
    // StripeCheckout,
    SettingsBlock,
    EmailIcon,
  },
  mixins: [goBack],
  computed: {
    email() {
      return this.$store.state.Settings.email;
    },
  },
  data() {
    return {
      stripeSubscriptionData: {
        loading: false,
        mode: "subscription",
        email: this.$store.state.Settings.email,
        user_id: this.$store.state.Auth.userId,
      },
      stripePaymentData: {
        loading: false,
        mode: "payment",
        email: this.$store.state.Settings.email,
        user_id: this.$store.state.Auth.userId,
        points: null,
      },
    };
  },
  methods: {
    async subscribe() {
      console.log(JSON.stringify(this.stripeSubscriptionData));
      let url = await this.$store.dispatch(
        "Payment/getStripeUrl",
        this.stripeSubscriptionData
      );
      this.nativeSendEvent("url", url);
      // await this.$router.push({ name: "Home" });
    },
    async pay(points) {
      this.stripePaymentData.points = points;
      console.log(JSON.stringify(this.stripePaymentData));
      let url = await this.$store.dispatch(
        "Payment/getStripeUrl",
        this.stripePaymentData
      );
      console.log("getStripeUrl: " + JSON.stringify(url));
      this.nativeSendEvent("url", url);
      this.goBack();
    },
     payStripe(points) {
       this.stripePaymentData.points = points;
      this.$router.push({
        name: "PaymentStripe",
        params: this.stripePaymentData,
      });
    },
    payInApp() {
      this.nativeSendEvent('purchase', { productId: 'wiki_ai_100_points' });
    },
    async cancel() {
      await this.$store.dispatch(
        "Payment/cancelSubscription",
        this.$store.state.Auth.userId
      );
      this.$store.commit("Auth/SET_STATUS", 0);
      await this.$router.push({ name: "Home" });
    },
  },
};
</script>
<style scoped>
.ai-price-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ai-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.ai-image {
  width: 15%;
}

.ai-title {
  margin-left: 10px;
  font-size: 24px;
}

.ai-price {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.ai-price > div {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
}

.ai-price > div::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 10px;
  width: calc(100% - 20px);
  border-bottom: 1px solid #ccc;
}

.points-title {
  margin-left: 10px;
  font-size: 18px;
}

.price-label-title {
  margin-right: 10px;
  font-size: 18px;
}

.points {
  margin-left: 10px;
}

.price-label {
  margin-right: 10px;
  font-size: 16px;
  color: #666;
}
</style>
