<template>
  <div class="itserve-center mt-25" style="height: 100%; text-align: center">
    <img
      src="@/assets/images/wiki_logo.png"
      width="150"
      class="company-logo m-t-10"
      style="border-radius: 50%"
    />
    <div class="info-container">
      <van-cell
        :title="`${$t('oferta.accept_info')}`"
        :title-style="{ color: '#1d1d1d' }"
        class="compact-cell"
      ></van-cell>
      <van-cell
        :title="`${$t('oferta.accept_info1')}`"
        :title-style="{ color: '#1d1d1d' }"
        class="compact-cell"
      ></van-cell>
      <van-cell
        :title="`${$t('oferta.accept_info2')}`"
        :title-style="{ color: '#1d1d1d' }"
        class="compact-cell"
      ></van-cell>
      <van-cell
        :title="`${$t('oferta.oferta')}`"
        :title-style="{ color: '#1d1d1d', 'text-decoration': 'underline' }"
        @click="ofertaPage"
        class="compact-cell"
      ></van-cell>
      <van-cell
        :title="`${$t('oferta.policy')}`"
        :title-style="{ color: '#1d1d1d', 'text-decoration': 'underline' }"
        @click="policyPage"
        class="compact-cell"
      ></van-cell>
    </div>
    <van-button
      class="mt-15 apply-loader"
      color="linear-gradient(to right, #63b3fb, #0086ff)"
      style="color: #cccccc"
      @click="confirm"
      >{{ $t("buttons.confirm") }}
    </van-button>
  </div>
</template>

<script>
import { Button, Cell } from "vant";

export default {
  name: "AppPolicy",
  components: {
    "van-button": Button,
    "van-cell": Cell,
  },
  methods: {
    ofertaPage() {
      this.$store.commit("Auth/SET_HIDDEN", true);
      this.$store.state.Auth.nativeData.agreement = true;
      this.$store.commit("Settings/AGREEMENT", true);
      this.$router.push({
        name: "OfertaPage",
        params: this.$route.params,
      });
    },
    policyPage() {
      this.$store.commit("Auth/SET_HIDDEN", true);
      this.$store.state.Auth.nativeData.agreement = true;
      this.$store.commit("Settings/AGREEMENT", true);
      this.$router.push({
        name: "PolicyPage",
        params: this.$route.params,
      });
    },
    confirm() {
      this.nativeSendEvent("confirmAgreenment", true);
      this.$store.state.Auth.nativeData.agreement = true;
      this.$store.commit("Settings/AGREEMENT", false);
      this.nativeSendEvent("preloadInterstitial");
    },
  },
};
</script>

<style scoped>
.info-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.compact-cell {
  padding: 15px;
}

@media screen and (max-height: 780px) {
  .compact-cell {
    padding: 7px;
  }
}
</style>
