<template>
  <van-popup v-model="deeplinkPopup" class="share-popup">
    <div class="popup-header">
      <i
        class="van-icon van-icon-cross close-icon"
        @click="closeDeeplinkPopup"
      ></i>
    </div>
    <div class="selected-messages-container">
      <div v-for="(item, index) in deepLinkData" :key="index">
        <p class="message-text" v-if="item.message">{{ item.message }}</p>
        <img
          class="message-image"
          v-if="item.image_url"
          :src="item.image_url"
          alt="Selected Image"
        />
        <p v-if="item.deeplink" class="message-text">
          <a class="deeplink" :href="item.deeplink">{{ item.deeplink }}</a>
        </p>
      </div>
    </div>
    <div class="share-button-container">
      <van-button
        class="share-button"
        type="info"
        @click="saveDeeplinkData"
        block
      >
        <p>{{ $t("deeplinkPopup.saveButton") }}</p>
      </van-button>
    </div>
  </van-popup>
</template>

<script>
import { Button, Popup } from "vant";
import { eventBus } from "@/plugins/eventBus";
// import defaultImage from "@/assets/images/wiki_logo.png";

export default {
  props: {
    deeplinkPopup: Boolean,
    deepLinkData: Array,
  },
  components: {
    "van-popup": Popup,
    "van-button": Button,
  },
  methods: {
    closeDeeplinkPopup() {
      this.$store.state.DeeplinkPopup.deepLinkPopup = false;
    },
    saveDeeplinkData() {
      this.selectDeepLinkData();
      this.closeDeeplinkPopup();
    },
    selectDeepLinkData() {
      eventBus.$emit("saveDeepLinkDataToDialogues");
    },
  },
};
</script>
<style scoped>
.message-image {
  width: 256px;
}

.message-text {
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
  font-size: 14px;
  line-height: 18px;
  color: #000000 !important;
  margin-left: 1%;
  margin-right: 1%;
}

.share-popup {
  height: 100%;
  width: 100%;
}

.popup-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.close-icon {
  font-size: 24px;
  cursor: pointer;
}

.share-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.share-button {
  width: 100%;
  max-width: none;
  background-color: #e0e5e5;
  border: none;
  color: #1d1d1d;
}

.selected-messages-container {
  position: fixed;
  max-height: 87%;
  overflow-y: auto;
  width: 100%;
  z-index: 9999;
  text-align: center;
}
</style>
