import settings from "@/plugins/api/factories/Settings";

export const state = {
  settings: null,
  phone: null,
  newPhone: null,
  agreement: false,
  email: null,
  advertisementPoints: null,
};

export const mutations = {
  STORE_SETTINGS(state, settings) {
    state.settings = settings;
  },
  STORE_PHONE(state, phone) {
    state.phone = phone;
  },
  UPDATE_LANGUAGE(state, language) {
    if (state.settings !== null) {
      state.settings.lang = language;
    }
  },
  UPDATE_NAME(state, name) {
    state.settings.name = name;
  },
  UPDATE_SURNAME(state, surname) {
    state.settings.surname = surname;
  },
  UPDATE_EMAIL(state, email) {
    state.settings.email = email;
  },
  EMAIL(state, email) {
    state.email = email;
  },
  UPDATE_PHONE(state, phone) {
    state.newPhone = phone;
  },
  AGREEMENT(state, agreement) {
    state.agreement = agreement;
  },
  STORE_ADVERTISEMENT_POINTS(state, advertisementPoints) {
    state.advertisementPoints = advertisementPoints;
  },
};

export const actions = {
  async getPhone({ commit }) {
    await settings.getPhone((response) => {
      commit("STORE_PHONE", response.phone);
    });
  },
  async getSettings({ commit }) {
    return new Promise((resolve, reject) => {
      settings.getSettings(
        (response) => {
          resolve(response);
          console.log("getSettings: " + JSON.stringify(response));
          commit("STORE_SETTINGS", response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async storeSettings({ state }) {
    console.log("storeSettings: " + JSON.stringify(state.settings));
    return await settings.storeSettings(state.settings);
  },
  async storeEmail({ state }) {
    console.log("storeEmail: " + JSON.stringify(state.email));
    return await settings.storeEmail(state.email);
  },
  async getEmail({ commit }) {
    return new Promise((resolve, reject) => {
      settings.getEmail(
        (response) => {
          resolve(response.email);
          console.log("getEmail: " + JSON.stringify(response.email));
          commit("EMAIL", response.email);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async storePhone({ state }) {
    return await settings.storePhone(state.newPhone);
  },
  async putLanguage({ commit }, language) {
    commit("UPDATE_LANGUAGE", language);
    return await settings.putLanguage(language);
  },
  storeAdvertisementPoints({ state }) {
    return settings.storeAdvertisementPoints(state.advertisementPoints);
  },
};
