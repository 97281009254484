import api from "@/plugins/api/EntryPoint";

export default {
  async getPaymentUrl(params, callBackFn) {
    return await api.post(`easy-pay/payment`, params, callBackFn);
  },
  async getEasyPayCardsList(callBackFn) {
    await api.post(`easy-pay/card-list`, null, callBackFn);
  },
  async getEasyPaySaveCardsPage(callBackFn) {
    await api.post(`easy-pay/3d-secure-url-alt`, {}, callBackFn);
  },
  async deleteCard(params, callBackFn) {
    console.log("DELETE CARD: " + JSON.stringify(params))
    return await api.post(`easy-pay/delete-card`, params, callBackFn);
  },
  async getStripe(params, callBackFn) {
    return await api.post('getUrl', params.stripeData, callBackFn)
  },
  async getStripeTest(params, callBackFn) {
    return await api.post('getUrlTest', params.stripeData, callBackFn)
  },
  async successClientPayment(params, callBackFn) {
    return await api.post('successClientPayment', params, callBackFn)
  },
  async createPaymentIntent(params, callBackFn) {
    return await api.post('createPaymentIntent', params.stripeData, callBackFn)
  },
  async cancelSubscription(params, callBackFn) {
    return await api.post('cancel-subscription', params, callBackFn)
  }
};
