export const state = {
  tries: 0,
  loading: false,
  loadingPost: false,
  allLoading: false,
  requestError: false,
  uploadingProgress: 0,
  errorTime: 0,
  errors: null,
  loaderHideTimeout: null,
};

export const mutations = {
  SET_UPLOADING_PROGRESS(state, progress) {
    // console.log("SET PROGRESS: " + progress);
    state.uploadingProgress = progress;
  },
  setValidationErrors(state, dataErrors) {
    state.errors = dataErrors;
  },
  resetValidationErrors(state) {
    state.errors = null;
  },
  deleteErrorByFieldName(state, fieldName) {
    delete state.errors.errors[fieldName];
  },
  setRetry(state) {
    state.tries++;
  },
  resetTries(state) {
    state.tries = 0;
  },
  showLoader(state) {
    clearInterval(state.loaderHideTimeout);
    state.loading = true;
  },
  showLoaderPost(state) {
    clearInterval(state.loaderHideTimeout);
    state.loadingPost = true;
  },
  showAllLoading(state) {
    clearInterval(state.loaderHideTimeout);
    state.allLoading = true;
  },
  hideLoader(state) {
    state.loaderHideTimeout = setTimeout(() => {
      state.loading = false;
      state.loadingPost = false;
      state.allLoading = false;
    }, 100);
  },
  setRequestError(state) {
    if (
        !state.requestError &&
        new Date().getTime() / 1000 - state.errorTime > 5
    ) {
      state.requestError = true;
      state.errorTime = new Date().getTime() / 1000;
    }
  },
  clearRequestError(state) {
    state.tries = 0;
    state.requestError = false;
  },
};
